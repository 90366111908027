  .m-jumbotron-steps-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: .6;
  background-color: $color-pr-dark;
}

.m-jumbotron-steps-home {
  position: relative;

  .m-steps-wrapper {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    padding-top: 2rem;
    justify-content: space-between;
    @media only screen and (max-width: $md) {
      justify-content: space-between !important;
      flex-direction: column;
      padding-top: 0;
    }
  }


  .m-steps-step {
    position: relative;
    width: 100%;
    max-width: calc(1140px / 3 - 2rem * 2); //2rem of margin between 2 of them (the last child doesn't need right margin)
    min-width: calc(1140px / 3 - 2rem * 2);
    background-color: $color-wt;
    box-shadow: 0 2px 3px 0 $color-shadow-main;
    @media only screen and (max-width: $md) {
      margin: 40px auto;
    }
    @media only screen and (max-width: $xxs) {
      max-width: 280px;
      min-width: 280px;
    }

    .e-step-title {
      position: absolute;
      left: 1rem;
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-end;

      p {
        color: $color-wt;
        font-weight: bold;
        font-size: 18px;
        padding-left: 1rem;
      }
    }

    &:nth-child(1) {
      .e-step-title {
        top: -46px;
      }
    }

    &:nth-child(2) {
      .e-step-title {
        top: -53px;
      }
    }

    &:nth-child(3) {
      .e-step-title {
        top: -49px;
      }
    }
  }
}

.m-panel-claim {
  .col-lg-4 {
    align-self: center;
  }

  .e-step-description {
    padding: 0;
  }

  .col-lg-8 {
    p {
      margin-top: 1rem;
      color: $color-pr-dark;
    }
  }

  .e-title-hero {
    font-size: 41px;
  }
}

.e-step-description {
  padding: 2rem;
  color: $color-pr-dark;
  font-size: 1rem;
  text-align: left;

  span {
    display: block;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: .5rem;
  }
}

// List fix

.show-list{
  list-style: disc!important;
  li{
      list-style:inherit!important;
      list-style-position: inside!important;
  }
}