.stickybar {
  padding: 10px 0;
  //background: rgba(199, 221, 233, .9);
  background: rgba(225, 225, 225, 0.8);
  width: 100%;
  position: fixed;
  top: -100px;
  visibility: hidden;
  z-index: 999;
  transition: all ease 1s;
  @media (max-width: $sm) {
    padding: 5px 0;
  }
  &.active {
    top: 0px;
    visibility: visible;
    @media (max-width: $sm) {
      display: none;
    }
  }
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .sticky-logo-desck {
    max-width: 133px;
    max-height: 50px;
    @media (max-width: $sm) {
      display: none;
    }
  }
  .sticky-logo-mobil {
    display: none;
    @media (max-width: $sm) {
      max-width: 36px;
      display: inline;
    }
  }
  .stickybar-button {
    text-align: right;
    #button-stickybar,
    #button-stickybar-renewal,
    #button-stickybar-urgente,
    #button-stickybar-ren {
      padding: 15px 10px;
      min-width: 125px;
      text-align: center;
      white-space: nowrap;
      @media (max-width: $sm) {
        padding: 10px 10px !important;
        &:after {
          border-top: 18px solid transparent;
          border-bottom: 18px solid transparent;
        }
      }
    }
    .e-buttons-nosizer:first-child {
      @media (max-width: $sm) {
        margin-right: 10px;
      }
    }
  }
}

.container-sticky-cookies {
  z-index: 100;
  @media (max-width: $sm) {
    position: fixed;
    bottom: 0;
    width: 100%;
    .stickybar {
      display: block;
      position: static;
      visibility: initial;
    }
    .sticky-logo-mobil,
    .sticky-logo-mobil {
      display: none;
    }
    .stickybar-button {
      width: 100%;
      text-align: center;
      display: flex;
    }
    .e-buttons-spacemenu {
      min-width: 125px;
      min-height: 45px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    .m-cookies-container {
      position: static;
    }
  }
}
#barraaceptacion {
  @media only screen and (max-width: 767px) {
    bottom: 55px;
  }
}
