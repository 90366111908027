.e-p {
  &-regular {
    font-size: 16px;
    line-height: 21px;
    &--light {
      color: $color-wt;
    }
    &--dark {
      color: $e-p-regular-dark-color;
    }
    &--blue {
      color: $color-pr-title;
    }
    &--disabled {
      color: $color-gr-light;
    }
    &--sc-darker {
      color: $color-sc-darker;
    }
    &--no-margin {
      margin: 0;
    }
    @media (max-width: $xs) {
      &-movile {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
  &--bold {
    font-weight: bold;
  }
  &-small {
    font-size: 13px;
    margin: 0 0 5px 0;
    &--light {
      color: $color-pr-lighter;
    }
    &--dark {
      color: $color-pr;
    }
    &--darker {
      color: $color-gr;
    }
  }
  &-clean {
    margin: 0;
    padding: 0;
  }
  &-xsmall {
    margin: 0 0 5px 0;
    font-size: 11px;
    font-style: italic;
  }
  &-svg {
    max-height: 14px;
    display: inline-block;
    position: relative;
    top: 2px;
    z-index: -1;
  }
  &-grey {
    color: $color-gr-dark;
  }

  &-quote {
    &::before {
      content: open-quote;
      position: absolute;
      margin-left: -20px;
      font-size: 35px;
      margin-top: 10px;
    }
    &::after {
      content: close-quote;
    }
  }
  &-jumbotron {
    padding: 1rem 1rem 0rem 1rem;
    color: #023b66;
    font-size: 1rem;
    text-align: left;
  }
}
.info-contact-reason {
  margin: 20px 0 0 0;
  font-size: 14px;
}
.info-contact-warning,
.info-contact-visa {
  margin: 0;
  font-size: 14px;
  color: #00f;
  line-height: 19px;
}
