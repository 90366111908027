.m-jumbotron {
  &-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.6;
    background-color: $color-pr-dark;
  }

  &-steps-home {
    background: url(#{$img-url}/jumbotron/bill.png);
    background-size: cover;
  }

  &-home {
    background: url(#{$img-url}/jumbotron/hero.jpg);
    background-size: cover;
    position: relative;
    @media only screen and (max-width: $sm) {
      background: url(#{$img-url}/jumbotron/hero-m.jpg);
      background-size: cover;
    }

    &--mx {
      background: url(#{$img-url}/jumbotron/hero-mx.jpg);
      background-size: cover;
      background-position: center;
      @media only screen and (max-width: $sm) {
        background: url(#{$img-url}/jumbotron/hero-mx-m.jpg);
        background-size: cover;
        background-position: center;
      }

      .e-title-subhero {
        font-size: 23px;
      }
    }

    &--canada {
      &:before {
        background: url(#{$img-url}/jumbotron/hero-canada.jpg) !important;
        background-size: cover !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
        @media only screen and (max-width: $sm) {
          background: url(#{$img-url}/jumbotron/hero-canada.jpg) !important;
          background-size: cover !important;
          background-position: center !important;
          background-repeat: no-repeat !important;
        }
      }

      .e-title-subhero {
        font-size: 23px;
      }
    }

    &--bo {
      background: url(#{$img-url}/jumbotron/hero-bo.jpg);
      background-size: cover;
      background-position: center;
      @media only screen and (max-width: $sm) {
        background: url(#{$img-url}/jumbotron/hero-bo.jpg);
        background-size: cover;
        background-position: center;
      }

      .e-title-subhero {
        font-size: 23px;
      }
    }

    &--ni {
      background: url(#{$img-url-common}/jumbotron/hero-ni.jpg);
      background-size: cover;
      background-position: center;
      @media only screen and (max-width: $sm) {
        background: url(#{$img-url-common}/jumbotron/hero-ni.jpg);
        background-size: cover;
        background-position: center;
      }

      .e-title-subhero {
        font-size: 23px;
      }
    }

    &--sv {
      background: url(#{$img-url}/jumbotron/hero-sv.jpg);
      background-size: cover;
      background-position: center;
      @media only screen and (max-width: $sm) {
        background: url(#{$img-url}/jumbotron/hero-sv.jpg);
        background-size: cover;
        background-position: center;
      }

      .e-title-subhero {
        font-size: 23px;
      }
    }

    &--pa {
      background: url(#{$img-url}/jumbotron/hero-pa.jpg);
      background-size: cover;
      background-position: center;
      @media only screen and (max-width: $sm) {
        background: url(#{$img-url}/jumbotron/hero-pa.jpg);
        background-size: cover;
        background-position: center;
      }

      .e-title-subhero {
        font-size: 23px;
      }
    }

    &--hn {
      background: url(#{$img-url}/jumbotron/hero-hn.jpg);
      background-size: cover;
      background-position: center;
      @media only screen and (max-width: $sm) {
        background: url(#{$img-url}/jumbotron/hero-hn.jpg);
        background-size: cover;
        background-position: center;
      }

      .e-title-subhero {
        font-size: 23px;
      }
    }

    &--pe {
      background: url(#{$img-url}/jumbotron/hero-pe.jpg);
      background-size: cover;
      background-position: center;
    }

    &--cr {
      background: url(#{$img-url}/jumbotron/hero-cr.jpg);
      background-size: cover;
      background-position: center;
      @media only screen and (max-width: $sm) {
        background: url(#{$img-url}/jumbotron/hero-cr.jpg);
        background-size: cover;
        background-position: center;
      }

      .e-title-subhero {
        font-size: 23px;
      }
    }

    &--ar {
      background: url(#{$img-url}/jumbotron/hero-ar.jpg);
      background-size: cover;
      background-position: center;
      @media only screen and (max-width: $sm) {
        background: url(#{$img-url}/jumbotron/hero-ar.jpg);
        background-size: cover;
        background-position: center;
      }

      .e-title-subhero {
        font-size: 23px;
      }
    }

    &--ec {
      background: url(#{$img-url}/jumbotron/hero-ec.jpg);
      background-size: cover;
      background-position: center;
      @media only screen and (max-width: $sm) {
        background: url(#{$img-url}/jumbotron/hero-ec.jpg);
        background-size: cover;
        background-position: center;
      }

      .e-title-subhero {
        font-size: 23px;
      }
    }

    &--ec2 {
      background: url(#{$img-url}/jumbotron/hero-ec2.jpg);
      background-size: cover;
      background-position: center;
      @media only screen and (max-width: $sm) {
        background: url(#{$img-url}/jumbotron/hero-ec2.jpg);
        background-size: cover;
        background-position: center;
      }

      .e-title-subhero {
        font-size: 23px;
      }
    }

    &--do {
      background: url(#{$img-url}/jumbotron/hero-do.jpg);
      background-size: cover;
      background-position: center;
      @media only screen and (max-width: $sm) {
        background: url(#{$img-url}/jumbotron/hero-do.jpg);
        background-size: cover;
        background-position: center;
      }

      .e-title-subhero {
        font-size: 23px;
      }
    }

    &--co {
      background: url(#{$img-url}/jumbotron/hero-co.jpg);
      background-size: cover;
      background-position: center;
      @media only screen and (max-width: $sm) {
        background: url(#{$img-url}/jumbotron/hero-co.jpg);
        background-size: cover;
        background-position: center;
      }

      .e-title-subhero {
        font-size: 23px;
      }
    }

    &--ca {
      background: url(#{$img-url-common}/jumbotron/hero-ca.jpg);
      background-size: cover;
      background-position: center;
      @media only screen and (max-width: $sm) {
        background: url(#{$img-url-common}/jumbotron/hero-ca.jpg);
        background-size: cover;
        background-position: center;
      }

      .e-title-subhero {
        font-size: 23px;
      }
    }

    &--bs {
      background: url(#{$img-url-common}/jumbotron/hero-bs.jpg);
      background-size: cover;
      background-position: center;
      @media only screen and (max-width: $sm) {
        background: url(#{$img-url-common}/jumbotron/hero-bs.jpg);
        background-size: cover;
        background-position: center;
      }

      .e-title-subhero {
        font-size: 23px;
      }
    }

    &--br {
      background: url(#{$img-url-common}/jumbotron/hero-br.jpg);
      background-size: cover;
      background-position: center;

      @media only screen and (max-width: $sm) {
        background: url(#{$img-url-common}/jumbotron/hero-br.jpg);
        background-size: cover;
        background-position: center;
      }
      .e-title-subhero {
        font-size: 23px;
      }
      &--portuguese {
      }
    }

    &--id {
      background: url(#{$img-url-common}/jumbotron/hero-id.jpg);
      background-size: cover;
      background-position: center;
      @media only screen and (max-width: $sm) {
        background: url(#{$img-url-common}/jumbotron/hero-id.jpg);
        background-size: cover;
        background-position: center;
      }

      .e-title-subhero {
        font-size: 23px;
      }
    }

    &--il {
      background: url(#{$img-url-common}/jumbotron/hero-il.jpg);
      background-size: cover;
      background-position: center;
      @media only screen and (max-width: $sm) {
        background: url(#{$img-url-common}/jumbotron/hero-il.jpg);
        background-size: cover;
        background-position: center;
      }
      .e-title-subhero {
        font-size: 23px;
      }
    }

    &--ae {
      background: url(#{$img-url-common}/jumbotron/hero-ae.jpg);
      background-size: cover;
      background-position: center;
      @media only screen and (max-width: $sm) {
        background: url(#{$img-url-common}/jumbotron/hero-ae.jpg);
        background-size: cover;
        background-position: center;
      }
      .e-title-subhero {
        font-size: 23px;
      }
    }

    &--ru {
      background: url(#{$img-url-common}/jumbotron/hero-ru.jpg);
      background-size: cover;
      background-position: right;
      @media only screen and (max-width: $sm) {
        background: url(#{$img-url-common}/jumbotron/hero-ru.jpg);
        background-size: cover;
        background-position: center;
      }
      .e-title-subhero {
        font-size: 23px;
      }
    }

    &--de {
      background: url(#{$img-url-common}/jumbotron/hero-de.png);
      background-size: cover;
      background-position: right;
      @media only screen and (max-width: $sm) {
        background: url(#{$img-url-common}/jumbotron/hero-de.png);
        background-size: cover;
        background-position: center;
      }
      .e-title-subhero {
        font-size: 23px;
      }
    }

    &--es {
      background: url(#{$img-url-common}/jumbotron/hero-es.jpg);
      background-size: cover;
      background-position: right;
      @media only screen and (max-width: $sm) {
        background: url(#{$img-url-common}/jumbotron/hero-es.jpg);
        background-size: cover;
        background-position: center;
      }
      .e-title-subhero {
        font-size: 23px;
      }
    }

    &--fr {
      background: url(#{$img-url-common}/jumbotron/hero-fr.png);
      background-size: cover;
      background-position: right;
      @media only screen and (max-width: $sm) {
        background: url(#{$img-url-common}/jumbotron/hero-fr.png);
        background-size: cover;
        background-position: center;
      }
      .e-title-subhero {
        font-size: 23px;
      }
    }

    &--it {
      background: url(#{$img-url-common}/jumbotron/hero-it.png);
      background-size: cover;
      background-position: right;
      @media only screen and (max-width: $sm) {
        background: url(#{$img-url-common}/jumbotron/hero-it.png);
        background-size: cover;
        background-position: center;
      }
      .e-title-subhero {
        font-size: 23px;
      }
    }

    &--pl {
      background: url(#{$img-url-common}/jumbotron/hero-pl.png);
      background-size: cover;
      background-position: right;
      @media only screen and (max-width: $sm) {
        background: url(#{$img-url-common}/jumbotron/hero-pl.png);
        background-size: cover;
        background-position: center;
      }
      .e-title-subhero {
        font-size: 23px;
      }
    }

    &--ro {
      background: url(#{$img-url-common}/jumbotron/hero-ro.jpg);
      background-size: cover;
      background-position: right;
      @media only screen and (max-width: $sm) {
        background: url(#{$img-url-common}/jumbotron/hero-ro.jpg);
        background-size: cover;
        background-position: center;
      }
      .e-title-subhero {
        font-size: 23px;
      }
    }

    &--gt {
      background: url(#{$img-url-common}/jumbotron/hero-gt.jpg);
      background-size: cover;
      background-position: center;
      @media only screen and (max-width: $sm) {
        background: url(#{$img-url-common}/jumbotron/hero-gt.jpg);
        background-size: cover;
        background-position: center;
      }

      .e-title-subhero {
        font-size: 23px;
      }
    }

    &--in {
      background: url(#{$img-url-common}/jumbotron/hero-in.jpg);
      background-size: cover;
      background-position: center;
      @media only screen and (max-width: $sm) {
        background: url(#{$img-url-common}/jumbotron/hero-in.jpg);
        background-size: cover;
        background-position: center;
      }

      .e-title-subhero {
        font-size: 23px;
      }
    }

    &--ph {
      background: url(#{$img-url-common}/jumbotron/hero-ph.jpg);
      background-size: cover;
      background-position: center;

      @media only screen and (max-width: $sm) {
        background: url(#{$img-url-common}/jumbotron/hero-ph.jpg);
        background-size: cover;
        background-position: center;
      }

      .e-title-subhero {
        font-size: 23px;
      }
    }

    &--jp {
      background: url(#{$img-url-common}/jumbotron/hero-jp.jpg);
      background-size: cover;
      background-position: center;
      @media only screen and (max-width: $sm) {
        background: url(#{$img-url-common}/jumbotron/hero-jp.jpg);
        background-size: cover;
        background-position: center;
      }

      .e-title-subhero {
        font-size: 23px;
      }
    }

    &--kr {
      background: url(#{$img-url-common}/jumbotron/hero-kr.jpg);
      background-size: cover;
      background-position: center;
      @media only screen and (max-width: $sm) {
        background: url(#{$img-url-common}/jumbotron/hero-kr.jpg);
        background-size: cover;
        background-position: center;
      }

      .e-title-subhero {
        font-size: 23px;
      }
    }

    &--ng {
      background: url(#{$img-url-common}/jumbotron/hero-ng.jpg);
      background-size: cover;
      background-position: right;
      @media only screen and (max-width: $sm) {
        background: url(#{$img-url-common}/jumbotron/hero-ng.jpg);
        background-size: cover;
        background-position: center;
      }
      .e-title-subhero {
        font-size: 23px;
      }
    }

    &--vn {
      background: url(#{$img-url-common}/jumbotron/hero-vn.jpg);
      background-size: cover;
      background-position: right;
      @media only screen and (max-width: $sm) {
        background: url(#{$img-url-common}/jumbotron/hero-vn.jpg);
        background-size: cover;
        background-position: center;
      }
      .e-title-subhero {
        font-size: 23px;
      }
    }

    &--th {
      background: url(#{$img-url-common}/jumbotron/hero-th.jpg);
      background-size: cover;
      background-position: right;
      @media only screen and (max-width: $sm) {
        background: url(#{$img-url-common}/jumbotron/hero-th.jpg);
        background-size: cover;
        background-position: center;
      }
      .e-title-subhero {
        font-size: 23px;
      }
    }

    &--hk {
      background: url(#{$img-url-common}/jumbotron/hero-hk.jpg);
      background-size: cover;
      background-position: right;
      @media only screen and (max-width: $sm) {
        background: url(#{$img-url-common}/jumbotron/hero-hk.jpg);
        background-size: cover;
        background-position: center;
      }
      .e-title-subhero {
        font-size: 23px;
      }
    }

    &--uy {
      background: url(#{$img-url-common}/jumbotron/hero-uy.jpg);
      background-size: cover;
      background-position: right;
      @media only screen and (max-width: $sm) {
        background: url(#{$img-url-common}/jumbotron/hero-uy.jpg);
        background-size: cover;
        background-position: center;
      }
      .e-title-subhero {
        font-size: 23px;
      }
    }

    &--sa {
      background: url(#{$img-url-common}/jumbotron/hero-sa.jpg);
      background-size: cover;
      background-position: right;
      @media only screen and (max-width: $sm) {
        background: url(#{$img-url-common}/jumbotron/hero-sa.jpg);
        background-size: cover;
        background-position: center;
      }
      .e-title-subhero {
        font-size: 23px;
      }
    }

    &--jm {
      background: url(#{$img-url-common}/jumbotron/hero-jm.jpg);
      background-size: cover;
      background-position: right;
      @media only screen and (max-width: $sm) {
        background: url(#{$img-url-common}/jumbotron/hero-jm.jpg);
        background-size: cover;
        background-position: center;
      }
    }

    &--eg {
      background: url(#{$img-url-common}/jumbotron/hero-eg.jpg);
      background-size: cover;
      background-position: right;
      @media only screen and (max-width: $sm) {
        background: url(#{$img-url-common}/jumbotron/hero-eg.jpg);
        background-size: cover;
        background-position: center;
      }
      .e-title-subhero {
        font-size: 23px;
      }
    }

    &--tt {
      background: url(#{$img-url-common}/jumbotron/hero-tt.jpg);
      background-size: cover;
      background-position: right;
      @media only screen and (max-width: $sm) {
        background: url(#{$img-url-common}/jumbotron/hero-tt.jpg);
        background-size: cover;
        background-position: center;
      }
      .e-title-subhero {
        font-size: 23px;
      }
    }

    &-container {
      @extend .l-container;
      display: flex;
    }

    &-aside {
      @extend .col-md-6;
      text-align: center;
      align-self: flex-end;
      @media only screen and (max-width: $md) {
        display: none;
      }

      &.align-left {
        order: 0;
      }
      &.align-right {
        order: 1;
      }

      img {
        vertical-align: bottom;
        max-height: 350px;
      }

      .m-panel-regular-body ul {
        @extend .e-list-hero;
        li {
          @extend .e-icon, .e-icon__green;
          line-height: 1.5;
          &::before {
            position: absolute;
            width: 20px;
            height: 17px;
            background-repeat: no-repeat;
            left: -2rem;
            top: 0;
            content: "\f10d";
            font-family: ifont !important;
            font-style: normal;
            font-weight: normal !important;
            vertical-align: top;
            color: #37b152;
          }
        }
      }
    }

    &-content {
      @extend .col-lg-6;
      padding: 30px 70px;
      background: url(#{$img-url}/jumbotron/bg.png);

      &.bg-solid {
        background: $color-wt;
      }

      &.m-d-layout {
        padding: 0;
        //max-width: 400px;
      }

      &.align-left {
        order: 0;
      }
      &.align-right {
        order: 1;
      }

      @media only screen and (max-width: $sm) {
        padding: 30px 43px;
      }

      .m-panel-regular-body ul {
        @extend .e-list-hero;
        li {
          @extend .e-icon, .e-icon__green;
          line-height: 1.5;
          &::before {
            position: absolute;
            width: 20px;
            height: 17px;
            background-repeat: no-repeat;
            left: -2rem;
            top: 0;
            content: "\f10d";
            font-family: ifont !important;
            font-style: normal;
            font-weight: normal !important;
            vertical-align: top;
            color: #37b152;
          }
        }
      }
    }

    &-extra {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    &--selector {
      @extend .m-jumbotron-home;
      overflow: hidden;
      background: none;

      &:before {
        content: "";
        background: url(#{$img-url}/jumbotron/hero.jpg);
        background-size: cover;
        width: 100%;
        min-height: 600px;
        height: 100%;
        position: absolute;
        filter: blur(4px);
        overflow: hidden;
        filter: brightness(0.7);
      }

      .m-jumbotron-home-aside {
        align-self: center;
        text-align: left;
        padding-top: 30px;

        p,
        h1,
        h2,
        li,
        span {
          color: $color-wt;
        }

        .m-panel-regular-body ul {
          @extend .e-list-hero;
          margin-left: 35px;
          li {
            @extend .e-icon, .e-icon__green;
            padding: 0;
            line-height: 1.5;
            &::before {
              position: absolute;
              width: 20px;
              height: 17px;
              background-repeat: no-repeat;
              left: -2rem;
              top: 0;
              content: "\f10d";
              font-family: ifont !important;
              font-style: normal;
              font-weight: normal !important;
              vertical-align: top;
              color: #37b152;
            }
          }
        }
      }

      label {
        display: block;
      }

      .e-radio-container {
        margin: 15px 0 0;
        display: flex;

        label {
          margin: 5px 5px;
        }
      }
    }
  }

  &--turismo {
    background: url(#{$img-url}/jumbotron/hero-2.jpg);
    background-size: cover;
    @media only screen and (max-width: $sm) {
      background: url(#{$img-url}/jumbotron/hero-m.jpg);
      background-size: cover;
    }
  }

  &--familias {
    background: url(#{$img-url}/jumbotron/hero-3.jpg);
    background-size: cover;
    @media only screen and (max-width: $sm) {
      background: url(#{$img-url}/jumbotron/hero-m.jpg);
      background-size: cover;
    }
  }

  &--grupos {
    background: url(#{$img-url}/jumbotron/hero-4.jpg);
    background-size: cover;
    @media only screen and (max-width: $sm) {
      background: url(#{$img-url}/jumbotron/hero-m.jpg);
      background-size: cover;
    }
  }

  &--canada-turismo {
    background: url(#{$img-url}/jumbotron/hero-canada-visitor.jpg);
    background-size: cover;
    @media only screen and (max-width: $sm) {
      background: url(#{$img-url}/jumbotron/hero-canada-visitor.jpg);
      background-size: cover;
    }
  }

  &--emergencia {
    margin-top: 0;
    background: url(#{$img-url}/jumbotron/jumbotron-emergencias.jpg);
    background-size: cover;
    @media only screen and (max-width: $sm) {
      background: url(#{$img-url}/jumbotron/jumbotron-emergencias.jpg);
      background-size: cover;
    }
    .m {
      &-panel {
        &-footer {
          /* padding-left: 0; */
        }
      }
      &-jumbotron {
        &-home {
          &-content {
            flex: 0 0 55%;
            max-width: 55%;
            @media only screen and (max-width: $md) {
              flex: 0 0 100%;
              max-width: 100%;
            }
          }
        }
      }
    }
    .e-list-hero.d-layout {
      padding: 20px 65px 0px 65px;
    }
    .e-title-subhero {
      padding-top: 0.5rem;
    }
    .e-p-xsmall-jumbotron {
      padding: 0rem 1rem 0rem 1rem;
      color: #023b66;
      font-size: 11px;
      text-align: left;
      &-bottom {
        font-size: 12px;
        color: #023b66;
        text-align: center;
        padding: 1rem;
      }
    }
    .e-subtitle-subhero {
      line-height: 25px;
      padding: 0px 15px 0px 15px;
    }
  }
}

.jumbotron-fullscreen {
  .m-jumbotron {
    &-home {
      margin-top: 0;

      &-container {
        padding-top: 100px;
        padding-bottom: 75px;

        @media screen and (min-width: $lg) {
          padding-top: 100px;
        }
      }

      &-content {
        margin-left: auto;
      }

      &--selector {
        &:before {
          height: 100%;
        }

        .e-title-hero {
          display: block;
        }
      }
    }
  }

  .m-page-header {
    padding-top: 8rem !important;
  }
}
.home-ph-canada {
  .m-jumbotron-home--ph {
    background-image: url(#{$img-url-common}/jumbotron/hero-ph-canada.jpg);
    @media only screen and (max-width: $sm) {
      background-image: url(#{$img-url-common}/jumbotron/hero-ph-canada.jpg);
    }
  }
}
