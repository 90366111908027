#fc_frame.fc-widget-normal {
    z-index: 100 !important;
}

.fc-form {
    padding: 15px !important;
}

.chat-fc-form-outer {
    font-size: 14px !important;
    z-index: 102 !important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Segoe UI Emoji", "Roboto", "Oxygen", "Ubuntu",
        "Fira Sans", "Droid Sans", "Cantarell", "Helvetica Neue", sans-serif !important;
    input {
        padding: 10px !important;
        font-size: 14px !important;
    }
}
