nav {
  display: none !important;
}

.m-nav-submenu-inline {
  display: none;
  .current-language--container--item {
    display: none;
  }
  .m-two-callToAction .l-half {
    padding: 0 10px;
  }
}

.m-nav {
  &-menu {
    background: $m-nav-color;
    display: flex;
    @media only screen and (max-width: $sm) {
      padding: 0 15px;
    }
    &-container {
      @extend .l-container;
      display: flex;
      justify-content: space-between;
      padding: 10px 0;
    }
    &-linksContainer {
      align-self: center;
    }
  }
  &-logo {
    display: flex;
    align-items: center;
    width: auto;
    max-height: 35px;
    @media only screen and (min-width: $sm) {
      max-height: 55px;
      height: 55px;
    }

    img {
      height: 35px;
      width: auto;
      /*       @media only screen and (max-width: $sm) {
        width: 100%;
      } */
      @media only screen and (min-width: $md) {
        height: 60px;
      }
    }
  }
  &-menuButton {
    display: inline-block;
    margin-left: 20px;
    vertical-align: middle;
    cursor: pointer;
    @media only screen and (min-width: $sm) {
      display: none;
    }
  }
  &-submenu {
    background: $m-nav-submenu-color;
    font-weight: 700;
    min-height: 45px;
    @media only screen and (max-width: $sm) {
      display: none;
      position: absolute;
      width: 100%;
      z-index: 99;
    }
    &-container {
      @extend .l-container;
      display: flex;
      padding: 0;
      background: $m-nav-submenu-container-color;
      min-height: 45px;
      justify-content: center;
      align-items: center;
      @media only screen and (max-width: $sm) {
        flex-direction: column;
        text-align: center;
      }

      .content-btn-nav {
        @media only screen and (min-width: $md) {
          margin-left: 15px;
          margin-right: 15px;
        }
      }

      .m-calltoaction {
        min-width: 350px;
        .l-half {
          flex: 0 0 50%;
          max-width: 50%;
        }
        a.e-buttons-regular {
          box-shadow: inherit;
        }
        @media only screen and (min-width: $md) {
          a.e-buttons-regular {
            height: 45px;
            padding: 15px 10px;
            white-space: nowrap;
            text-transform: initial;
            color: $e-button-text-color !important;
          }
        }
      }
    }
    a {
      padding: 15px 10px;
      color: $m-nav-submenu-a-color;
      display: block;
      font-size: 14px;
      text-decoration: none;
      font-family: $font-sc;
      &:hover {
        color: $color-pr-light;
      }
      &.e-buttons-nosizer {
        color: $e-button-text-color;
      }
    }
  }
}

.autorizacion,
.authorization {
  .m-nav-submenu-container {
    .e-menu-autorizacion {
      flex: 1;
    }
  }
}

.jumbotron-fullscreen.citas-emergencia
  .m-nav-submenu-inline
  .m-two-callToAction {
  min-width: 330px;
}

.citas-emergencia {
  .m-nav {
    &-submenu {
      &-container {
        .m-two-callToAction {
          .e-buttons-spacemenu {
            padding: 18px 30px !important;
            &:hover {
              box-shadow: inset 210px 0 0 0 #31a848 !important;
            }
          }
        }
      }
    }
  }
}

.jumbotron-fullscreen {
  nav {
    position: absolute;
    z-index: 99;
    width: 100%;
    margin-top: 15px;

    @media screen and (min-width: $sm) {
      margin-top: 0;
    }
  }

  .m-nav {
    &-menu {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.4), transparent);
      &-container {
        padding-top: 0;
      }
      &-linksContainer {
        z-index: 99;
        @media screen and (min-width: $lg) {
          display: none;
        }
      }
      @at-root .new-voucher .m-nav-menu {
        background: initial;
      }
    }
    &-menuButton {
      display: inline-block;
      background: $color-gr-lightest;
      padding: 5px 5px 3px;
    }
  }

  .m-nav-submenu {
    display: none;
    width: 100%;
    position: absolute;
    top: 0px;
    padding-top: 15px;
    padding-bottom: 15px;
    z-index: 9;

    @media screen and (min-width: $lg) {
      display: none !important;
    }

    &-inline {
      display: none;
      padding-top: 25px;

      @media screen and (min-width: $lg) {
        display: block;
      }
      a {
        padding: 15px 10px;
        color: $m-nav-submenu-a-color;
        display: block;
        display: block;
        font-size: 14px;
        text-decoration: none;
        font-family: $font-sc;
        font-weight: bold;
        /*         &:hover {
          color: $color-pr-light;
        }
        @at-root .pages .m-nav-submenu-inline a {
          color: $color-pr;
        } */
        /* 
        @at-root a.e-buttons-nosizer {
          color: $m-nav-submenu-a-color !important;
        } */
      }
      .m-two-callToAction a {
        color: $e-button-text-color;
      }
    }
  }
}
