.m-twocoltext-button-container {
  .l-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .l-half + .l-half {
      margin-top: 30px;
    }
  }
  .m-two-callToAction {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .l-row {
      width: 100%;
    }
  }
}

.m-two-callToAction {
  margin: 0 auto;

  @at-root .m-nav-submenu & {
    margin: auto;
    margin-left: auto;

    @media screen and (min-width: $lg) {
      margin: initial;
    }
  }

  @media (min-width: $sm) {
    align-self: center;
  }

  .jumbo-btn-content {
    /*     @media (max-width: $sm) {
      width: 415px;
      margin-left: -40px;
    } */
    @media (max-width: $xs) {
      width: 100%;
      margin-left: 0px;
    }
  }
  .l-half {
    margin-bottom: 10px;
    @media (min-width: $lg) {
      margin-bottom: initial;
    }
    @media screen and (min-width: $sm) and (max-width: $lg) {
      .e-buttons-regular {
        font-size: 13px;
      }
    }
  }

  /*   .l-center {
    margin: 0 auto;
    max-width: 500px;
  } */
  .e-buttons-regular {
    width: 100%;
    text-align: center;
    padding: 23px 15px 23px 15px;
    @media (max-width: $md) {
      margin-bottom: 10px;
    }
    @media (min-width: $md) {
      max-width: 300px;
    }
  }
  .e-buttons-spacemenu {
    margin-left: 20px;
  }
  #apply-advisor1-test,
  #apply-advisor1-renewal,
  #apply-advisor1,
  #apply-advisor2-test,
  #apply-advisor2-renewal,
  #apply-advisor2,
  #apply-advisor3-test,
  #apply-advisor3-renewal,
  #apply-advisor3,
  #apply-advisor4-test,
  #apply-advisor4-renewal,
  #apply-advisor4,
  #apply-advisor5-test,
  #apply-advisor5-renewal,
  #apply-advisor5,
  #apply-advisor6-test,
  #apply-advisor6-renewal,
  #apply-advisor6,
  #apply-advisor7-test,
  #apply-advisor7-renewal,
  #apply-advisor7,
  #apply-advisor8-test,
  #apply-advisor8-renewal,
  #apply-advisor8 {
    @media (max-width: $sm) {
      padding: 23px 10px 23px 10px;
      font-size: 13px;
    }
    @media (max-width: 767px) {
      padding: 23px 15px 23px 15px;
      font-size: 15px;
    }
  }
  .content-btn-nav {
    @media (max-width: 1200px) {
      position: absolute;
      /* top: 5px;
      right: 0px; */
      top: 1.3rem;
      right: 15px;
    }
    @media (max-width: 767px) {
      position: initial;
    }
    #button-navMenu {
      float: left;
      margin-right: 11px;
    }
    #button-navMenu,
    #button-navMenu-renewal,
    #button-navMenu-ren {
      padding: 15px 10px !important;
      // width: 150px;
      text-align: center;
      @media screen and (min-width: $lg) {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      @media (max-width: 1200px) {
        /* width: 60%; */
        padding: 10px 10px !important;
        margin-bottom: 5px;
      }
      @media (max-width: 767px) {
        width: 150px;
        padding: 15px 10px;
        margin-bottom: 20px;
        margin-left: 0;
      }
    }
  }

  &.l-half {
    .e-buttons-regular {
      max-width: 300px;
    }
  }
}
