.e-image {
  width: 150px;
  height: 150px;

  &--default-icon {
    background-repeat: no-repeat;
    background-size: contain;
  }

  &--form {
    @extend .e-image;
    background-image: url("#{$img-url}/icons/advisor/form.svg");
    @extend .e-image--default-icon;
  }
  &--server {
    @extend .e-image;
    background-image: url("#{$img-url}/icons/advisor/server.svg");
    @extend .e-image--default-icon;
  }
  &--time {
    @extend .e-image;
    background-image: url("#{$img-url}/icons/advisor/time.svg");
    @extend .e-image--default-icon;
  }
  &--discount {
    @extend .e-image;
    background-image: url("#{$img-url}/icons/advisor/discount.svg");
    @extend .e-image--default-icon;
  }
  &--advisor {
    @extend .e-image;
    background-image: url("#{$img-url}/icons/advisor/advisor.svg");
    @extend .e-image--default-icon;
  }
  &--find {
    @extend .e-image;
    background-image: url("#{$img-url}/icons/advisor/find.svg");
    @extend .e-image--default-icon;
  }
  &--insurance {
    @extend .e-image;
    background-image: url("#{$img-url}/icons/insurance.svg");
    @extend .e-image--default-icon;
  }

  &-align-left {
    flex-direction: row-reverse;
  }
}
