.m-quote {
   &-container {
     display: flex;
     margin-bottom: 25px;
   }

   &-content {
     background: url(#{$img-url-common}/icons/quote-mark.svg);
     background-position: 0 40px;
     background-repeat: no-repeat;
     padding-left: 50px;
     color: $color-pr;
   }
   &-thumbnail {
     margin-right: 30px;
     max-width: 150px;
     width: 100%;
     height: 100%;
     margin-bottom: 25px;
     @media only screen and (max-width: $md) {
       display: none;
     }
   }
 }