.m-cards-regular {
  max-width: $md;
  width: 100%;
  margin: 4rem auto 0 auto;
  align-items: center;
  justify-content: center;
}

.m-card-regular-header {
  text-align: center;
  padding: 1rem 0;
  background-color: $color-bg-jumbotron;
  margin-bottom: 2rem;
}

.m-card-main,
.m-card-narrow {
  background-color: $color-wt;
}

.m-card-narrow {
  box-shadow: inset -14px 1px 5px 0 $color-shadow-ligthest, 2px 3px 4px 0 $color-shadow-ligther;
  max-width: 390px;
  width: 100%;
  min-height: 420px;
  background-color: $color-gr-lighter;

  p {
    padding: 2rem 0;
    margin-bottom: 2rem;
  }

  @media only screen and (max-width: $md) {
    order: 2;
    box-shadow: 2px 3px 4px 0 $color-shadow-ligther;

  }
}

.m-card-main {
  box-shadow: 2px 3px 4px 0 $color-shadow-ligther;
  max-width: 400px;
  width: 100%;
  /* height: 440px; */
  @media only screen and (max-width: $md) {
    order: 1;
    margin-bottom: 1.5rem;
  }

  .m-panel-hero-footer {
    margin-bottom: 2rem;
  }
}

.e-card-list {
  padding-left: 4rem;
  padding-right: 1.5rem;
  padding-bottom: 1rem;

  li {
    position: relative;
    margin-bottom: .5rem;
    color: $color-pr-darker;

    &:before {
      position: absolute;
      width: 1rem;
      height: 1rem;
      left: -2rem;
    }
  }

  &-cross {

    &:before {
      background: url(#{$img-url-common}/icons/benefits/close-red.svg) no-repeat center center / cover;
    }
  }

  &-check {
    margin-bottom: 2rem;

    &:before {
      background: url(#{$img-url-common}/icons/check-green.svg) no-repeat center center / cover;
    }
  }
}