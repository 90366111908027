.e-text {
  background-color: $color-wt;
  border: 1px solid $color-gr-light;
  padding: 10px;
  font-weight: 100;
  width: 100%;
  font-size: 16px;
  height: 40px;
  font-family: $font-pr;
  @media (max-width: 767px) {
    height: 38px;
    padding: 8px;
  }

  &-info {
    padding: 25px;
    background: $color-pr-light;
    margin-top: 15px;
    margin-bottom: 15px;

    .e-tooltip {
      display: none;
    }

    h3 {
      line-height: 1.4;
    }
  }
}

.m-txt {
  /*COLOR*/
  &color- {
    &primary {
      color: $color-pr;
    }
    &gray {
      color: $color-gr;
    }
  }
  /*FONT SIZE*/
}
.svg {
  &-terms {
    &-dims {
      margin-bottom: -6px;
      height: 19px;
      &--white {
        filter: invert(1);
      }
    }
    &-floor {
      height: 16px;
      margin-bottom: -3px;
    }
  }
  &-zip.svg-terms-dims {
    height: 15px;
    margin-bottom: -3px;
    margin-right: 8px;
  }
  &-country.svg-terms-dims {
    height: 16px;
    margin-bottom: -4px;
  }

  &-legal {
    &-dims {
      margin-bottom: -6px;
      margin-right: -1px;
      height: 18px;
      &--white {
        filter: invert(1);
      }
    }
  }
}
