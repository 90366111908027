// JS required
.js-accordion-toggle {
  cursor: pointer;
}
.js-accordion-desktop-disabled .js-accordion-toggle {
  cursor: initial;
}
.js-accordion-content {
  display: none;
}
.js-accordion-content.default {
  display: block;
}

//Style Accordion module
.m-accordion {
  &-title {
    font-weight: 700;
    display: flex;
    margin: 0 0 10px;
    border: 1px solid $color-wt;
    padding: 6px;
    color: $color-wt;
    &-text {
      margin: 5px 0 0;
    }
  }
  &-content {
    padding: 5px 15px 10px;
    color: $color-wt;
  }
  &-countries {
    align-content: space-evenly;
    @media (min-width: $md) {
      display: grid;
      grid-auto-flow: row;
      grid-auto-columns: 1fr;
      gap: 1.2rem;
    }
    .m-accordion-continent {
      width: 100%;
      @media (min-width: $lg) {
        height: 100%;
        /* width: calc(33% - 2rem); */
      }
      h2 {
        font-size: 16px;
        color: $color-bk;
        font-weight: 600;
        line-height: 1.5;
      }
      .button {
        display: flex;
        justify-content: left;
        text-align: left;
        padding: 6px 10px 4px 10px;

        &:hover {
          background-color: $color-pr-light;
        }

        &:active {
          background-color: $color-pr-light;
        }

        @media (min-width: $lg) {
          &:active:after {
            content: "";
            background: url(#{$img-url-common}/icons/check-green.svg);
            background-repeat: no-repeat;
            background-size: contain;
            position: relative;
            display: inline-block;
            width: 18px;
            height: 18px;
            align-self: center;
            margin-left: auto;
          }
        }
      }
      .m-accordion-content {
        padding: 0;
        display: none;
        &--container {
          display: grid;
          grid-gap: 1rem;
          grid-template-columns: 1fr 1fr;
          padding-bottom: 1rem;
          @media (min-width: $md) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
          }
        }
        /*         @at-root .m-accordion-countries
            .m-accordion-continent
            .accordion-open
            + .m-accordion-content
            .m-accordion-content--container {
          @media (min-width: $md) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
          }
        } */
        @media (min-width: $md) {
          /*           display: grid !important;
          grid-gap: 1rem;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          padding-bottom: 0; */
        }
      }
    }
    .m-accordion-toggle {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $color-gr-dark;
      margin-bottom: 1rem;

      &:after {
        content: "+";
        position: relative;
        display: inline-block;
        width: 15px;
        height: 18px;
        align-self: center;
      }
      &.accordion-open {
        &:after {
          content: "–";
        }
      }
    }
  }
}

//Style Accordion FAQS
.faqs-accordion {
  &-content {
    border-bottom: 1px solid $color-gr-light;
    padding-top: 20px;
    h2 {
      position: relative;
      padding-right: 50px;
      margin-bottom: 20px;
      line-height: 30px;
      @media only screen and (max-width: $sm) {
        line-height: 22px;
      }
      &:after {
        position: absolute;
        top: 33%;
        right: 20px;
        content: "";
        display: inline-block;
        width: 0.5em;
        height: 0.5em;
        border-top: 3px solid $color-pr;
        border-right: 3px solid $color-pr;
        -moz-transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
      }
    }
    ol {
      margin-left: 30px;
    }
    .arrow-up {
      &:after {
        top: 43%;
        -moz-transform: rotate(315deg);
        -webkit-transform: rotate(315deg);
        transform: rotate(315deg);
      }
    }
    .close-up {
      &:after {
        position: absolute;
        content: "×";
        font-size: 1.3em;
        color: #000;
        top: 50%;
        right: 17px;
        transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        border-top: none;
        border-right: none;
      }
    }
    a {
      color: $color-pr;
    }
  }
  &-content:last-child {
    border-bottom: none;
  }
}
