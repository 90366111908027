.e-textarea {
  background-color: $color-wt;
  border: 1px solid $color-gr-light;
  padding: 10px;
  font-weight: 100;
  font-family: $font-pr;
  width: 100%;
  max-width: 100%!important; 
  font-size: 16px;
  min-height: 140px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 140px;
  resize: none;
  @media (max-width: 767px) {
    height: 38px;
  }
}
.e-character-count{
  font-size: 13px;
  text-align: right;
  color: #727272;
}
.maxlength-info{
  font-size: 12px;
}