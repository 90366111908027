.e-icons{
  &-bg {
    background: $color-pr-light;
    padding: 20px;
    border-radius: 80px;
    display: inline-block;
    width: 124px;
    height: 124px;
  }
  &-nextBg{
    &:after{
      content: "";
      background: url(#{$img-url-common}/icons/next.svg);
      position: absolute;
      right: -10px;
      width: 19px;
      height: 33px;
      top: 41px;
      @media only screen and (max-width: $sm) {
        background: url(#{$img-url-common}/icons/down.svg);
        width: 33px;
        height: 19px;
        bottom: -36px;
        top: auto;
        right: 92px;
      }
    }
  }
  &-nextBg--light{
    &:after{
      content: "";
      background: url(#{$img-url-common}/icons/next-blue.svg);
      position: absolute;
      right: -5px;
      width: 19px;
      height: 33px;
      top: 41px;
      @media only screen and (max-width: $sm) {
        background: url(#{$img-url-common}/icons/down-blue.svg);
        width: 33px;
        height: 19px;
        bottom: -36px;
        top: auto;
        right: 92px;
      }
    }
  }
  &-svg-big {
    width: 100%;
    max-width: 325px;
  }
}