span.e-title-hero {
  display: inline-block;
}

.e-title {

  /* type: hero */
  &-hero {
    font-size: 32px;
    line-height: 46px;
    color: $e-title-hero-color;
    font-family: $font-sc;
    margin: 0;
    @media only screen and (max-width: $sm) {
      font-size: 26px;
      line-height: 32px;
    }
    @media only screen and (max-width: $xs) {
      &-small-movile{
        font-size: 24px;
        line-height: 22px;  
      }
    }

    &--blue-dark {
      color: $color-pr-dark;
    }

    &--light {
      color: $color-wt;
      @media only screen and (max-width: $xxs) {
        padding: 0 25px;
      }
    }

    &--dark {
      color: $color-bk;
    }

    &--extrapadding {
      margin: 15px 0;
    }

    &-country {
      display: block;
      font-size: 22px;
      @media screen and (min-width: $md) {
        display: inline-block;
        font-size: 32px;
      }
    }

  }

  /* type: hero */
  &-subhero {
    font-size: 20px;
    color: $e-title-subhero-color;
    font-family: $font-sc;
    margin: 0;
    @media only screen and (max-width: $sm) {
      font-size: 22px;
    }

    &--light {
      color: $color-wt;
    }

    &--blue-dark {
      color: $color-pr-dark !important;
    }

    &--dark {
      color: $color-bk;
    }

    &-mini {
      font-size: 20px;
      color: $color-pr;
      font-family: $font-sc;
      margin: 0;
    }

    &--bold {
      font-weight: bold;
    }

    &--medium {
      font-weight: 500;
    }

    &--centred {
      text-align: center;
    }
  }

  &-container {
    &-eng {
      margin-right: 50px;
      @media screen and (min-width: $md) {
        margin-right: 0;
      }
    }
  }

  &-subparagraph {
    font-size: 18px;
  }

  /* type: medium */
  &-medium {
    font-size: 22px;
    line-height: 35px;
    color: $e-title-medium-color;
    margin: 0;
    padding: 10px 0;
    @media only screen and (max-width: $sm) {
      font-size: 18px;
    }

    &--light {
      font-family: $font-sc;
      color: $color-wt;
      @media only screen and (max-width: $sm) {
        padding: 5px 0;
      }
    }

    &--dark {
      font-family: $font-sc;
      padding: 10px 0;
      color: $color-pr-dark;
    }

    &--bold {
      font-weight: bold;
    }
    
  }

  &-small {
    color: black;
    font-size: 16px;
    line-height: 1.6;
    @media only screen and (max-width: $sm) {
      font-size: 14px;
      line-height: 1.5;
    }

    &--light {
      color: $color-wt;
      padding: 5px 0;
    }

  }

  &-button {
    float: right;
  }

  &--centred {
    text-align: center;
    justify-content: center;
  }

  &--bold {
    font-weight: bold;
  }
}

.e-d-layout {
  display: flex;
  flex-flow: row wrap;
  padding-top: .75rem;
  background-color: $color-bg-jumbotron;
  @media only screen and (max-width: $xxs) {
    padding: 10px 10px 10px 10px;
  }

  .e-title-subhero,
  .e-subtitle-subhero {
    flex-basis: 100%;
    color: $color-pr-darker;
    text-align: center;
  }

  .e-title-subhero {
    font-size: 36px;
    font-weight: bold;
    padding-bottom: 1rem;
  }
}

.e-subtitle-subhero {
  font-size: 18px;
  &--light {
    color: $color-wt;
    @media only screen and (max-width: $xxs) {
      padding: 0 25px;
    }
  }
}