.testimonials-responsive{
    .m-panel-regular-header{
        justify-content: center;
    }
}

.m-jumbotron-steps-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: .6;
    background-color: $color-pr-dark;
  }
  
  .m-jumbotron-steps-home {
    position: relative;
  
    .m-testimonials-wrapper {
      max-width: 1140px;
      width: 100%;
      margin: 0 auto;
      padding-top: 2rem;
      @media only screen and (max-width: $md) {
        justify-content: space-evenly !important;
        flex-direction: column;
        padding-top: 0;
      }
    }
  
  
    .m-testimonials-panel {
      position: relative;
      width: 100%;
      max-width: calc(1140px / 3 - 2rem * 2); //2rem of margin between 2 of them (the last child doesn't need right margin)
      min-width: calc(1140px / 3 - 2rem * 2);
      background-color: $color-wt;
      box-shadow: 0 2px 3px 0 $color-shadow-main;
  
      
      @media only screen and (max-width: $md) {
        margin: 40px auto;
      }
      @media only screen and (max-width: $xxs) {
        max-width: 280px;
        min-width: 280px;
      }
  
      .e-step-title {
        position: absolute;
        left: 1rem;
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-end;
  
        p {
          color: $color-wt;
          font-weight: bold;
          font-size: 18px;
          padding-left: 1rem;
        }
      }
  
      &:nth-child(1) {
        .e-step-title {
          top: -46px;
        }
      }
  
      &:nth-child(2) {
        .e-step-title {
          top: -53px;
        }
      }
  
      &:nth-child(3) {
        .e-step-title {
          top: -49px;
        }
      }
    }
  }
  
  .m-testimonials {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    &:nth-child(odd){
        margin-top: 30px;
      }


    .m-panel{
        &-content{
            text-align: center;
            padding-bottom: 15px;
        }
    }

    /*orientation: vertical sm only*/
    &-v-sm {
      @media only screen and (max-width: $sm) {
        flex-direction: column;
      }
    }
  
    &-panel {
      padding: 25px 25px 25px 25px;
      flex: 1;
      box-shadow: 1px 1px 6px -2px rgba(0,0,0,.35);
      border-radius: 12px;
      &:nth-child(odd){
        margin-right: 30px;
        @media only screen and (max-width: $sm) {
          margin-right: 0px;
        }
      }
      @media only screen and (max-width: $sm) {
        align-self: center;
        margin-bottom: 30px;
      }

      &-col-1-5 {
        width:calc(20% - 15px);
  
        img {
          width:100%;
        }
      }
      .e-quoteicon{
          margin-bottom: 15px;
      } 
    }
  
    &-progress {
      text-align: center;
      align-self: center;
      // margin-bottom: 30px;
      margin-bottom: 20px;
      width: 90%;
  
      &-begin {
        img {
          width: 22px;
          height: 22px;
          position: relative;
          top: -12px;
        }
      }
  
      &-first {
        border-top: 2px solid $color-sc-darker;
        width: 50%;
        margin-left: 50%;
  
        img {
          width: 22px;
          height: 22px;
          opacity: 0;
          position: relative;
          left: 49%;
          top: -12px;
        }
      }
  
      &-full {
        border-top: 2px solid $color-sc-darker;
  
        img {
          height: 22px;
          opacity: 0;
          position: relative;
          left: 49%;
          top: -12px;
        }
      }
  
      &-half {
        border-top: 2px solid $color-sc-darker;
        width: 50%;
  
        img {
          height: 22px;
          position: relative;
          left: 49%;
          top: -12px;
        }
      }
  
      &-empty {
        img {
          height: 22px;
          opacity: 0;
          position: relative;
          left: 49%;
          top: -12px;
        }
      }
  
      .m-panel-content {
        padding: 0 7px;

      }
    }
  
    &-stepBg {
      width: 215px;
      text-align: center;
      padding: 0 7px;
      position: relative;
      @media only screen and (max-width: $sm) {
        align-self: center;
        margin-bottom: 55px;
      }
  
      &:last-child {
        @media only screen and (max-width: $sm) {
          margin-bottom: 0;
        }
      }
    }
  }
  
  .m-panel-claim {
    .col-lg-4 {
      align-self: center;
    }
  
    .e-step-description {
      padding: 0;
    }
  
    .col-lg-8 {
      p {
        margin-top: 1rem;
        color: $color-pr-dark;
      }
    }
  
    .e-title-hero {
      font-size: 41px;
    }
  }
  
  .e-step-description {
    padding: 2rem;
    color: $color-pr-dark;
    font-size: 1rem;
    text-align: left;
  
    span {
      display: block;
      font-size: 20px;
      font-weight: bold;
      margin-bottom: .5rem;
    }
  }

  .e{
    &-p{
      &-testimonial{
        font-size: 13px;
        text-align: right!important;
        padding-top: 10px;
      }
    }
  }
  