.l-container{
  @extend .container;
}
.l-container-narrow{
  @extend .container;
  // max-width: 700px;
  width: 100%;
  
}
.l-container-medium{
  @extend .container;
  max-width: 830px;
}
.l-row{
  @extend .row;
}
.l-row-reverse {
  flex-direction: row-reverse;
}

.justify-content-start {
  -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
          justify-content: flex-start !important;
}

.justify-content-end {
  -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
          justify-content: flex-end !important;
}

.l-justify {
  &-left{
    @extend .justify-content-start;
    margin-left: 0;
    margin-right: 100%;
  }
  &-right{
    display: flex;
    @extend .justify-content-end;
    @media only screen and (max-width: $sm) {
      display: initial;
      margin: 0 auto;
    }
  }
}


.l-full {
  @extend .col-sm-12;
}

/* half screen - full device */
.l-half{
  @extend .col-lg-6;
  @extend .col-12;
  @media only screen and (max-width: $sm) {
    margin: 0 auto;
  }
}

form .l-half{
  @extend .col-xl-6;
  @extend .col-12;
  @media only screen and (max-width: $sm) {
    margin: 0 auto;
  }
}

/* half screen - half mobile */
.l-half-half{
  @extend .col-6;
}

/* third screen - full mobile */
.l-third{
  @extend .col-sm-4;
  //max-width: 540px;
}

/* third screen - half mobile */
.l-third-half{
  @extend .col-sm-4;
  @extend .col-6;
  //max-width: 540px;
}

/* third screen - half tablet */
.l-third-half-tablet {
  @extend .col-md-4;
  @extend .col-sm-6;
  @extend .col-12;
}

.l-main{
  @extend .col-md-8;
  @extend .col-12;
}

.l-main-tablet {
  @extend .col-xl-8;
  @extend .col-12;
}

.l-main-long{
  @extend .col-lg-9;
  @extend .col-12;
}

.l-aside{
  @extend .col-md-4;
  @extend .col-12;
}

.l-aside-narrow{
  @extend .col-lg-3;
  @extend .col-md-12;
}

.l-center{
  text-align: center;
  margin: 0;
  &--column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &-mobile{
    @media only screen and (max-width: $sm) {
      text-align: center;
    }
  }
  &.m-panel-hero-footer {
    margin: 0 auto;
    max-width: 500px;
  }
}
.l-left {
  text-align: left;
  &--column {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
  }
}
.l-left{
  text-align: left;
  margin: 0;
}
.l-right{
  text-align: right;
  margin: 0;
}



