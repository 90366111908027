.e-sticky {
  position: relative;
  z-index: 2;
  max-width: 100%!important;
  &.fixed{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    margin-top: 0;
    z-index: 1;
    &.absolute{
      position: absolute;
    }
  }
  &-wrap{
  width: 100%;
  height: 90px!important;
  }
}