.e-select {
  border: 1px solid $color-gr-light;
  font-weight: 100;
  padding: 10px;
  width: 100%;
  font-size: 16px;
  height: 40px;
  font-family: $font-pr;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url(#{$img-url-common}/icons/arrow-down--bk.svg) no-repeat scroll 97% $color-wt;
  background-size:20px;
  color: $form-label-color;

  &#purpose {
    @media (max-width: $sm) {
        font-size: 12px;
    }
  }

  @media (max-width: $sm) {
    height: 38px;
    padding: 8px;
  }
  @media (max-width: $xs) {
    background-position: 95%;
  }


  &-countrycode {
    position: relative;
    .e-country-box {
        width: 50%;
        vertical-align: top;
        display: inline-block;
        margin-right: 0;
        border-right: 0;
        text-align: center;
        float: left;
        padding: 10px;

        @media screen and (min-width:$md) {
            width:30%;
        }

        @at-root .l-half .e-select-countrycode .e-country-box {
            width: 45%;
        }

        .fflag {
            margin-top: -3px;
        }
    }

    .country-code {
        vertical-align: middle;
    }

    .arrow-down:before {
        content: '';
        background: url(#{$img-url-common}/icons/arrow-down--bk.svg) no-repeat scroll 97% #fff;
        position: relative;
        display: inline-block;
        width: 15px;
        height: 18px;
        vertical-align: middle;
    }

    .e-input-telephone {
        display: inline-block;
        width: 50%;
        
        @media screen and (min-width:$md) {
            width:70%;
        }

        @at-root .l-half .e-select-countrycode .e-input-telephone {
            width:55%;
        }
    }


    .country-dropdown {
        display: none;
        background:$color-wt;
        z-index: 9;
        position: absolute;
        height: 40vh;
        overflow-y: scroll;
        top: 40px;
        box-shadow: 0px 0px 14px lightgrey;
        outline: 2px #7fb3ff;

        @media screen and (max-width:$md) {
            max-width: 75vw;
        }

        @media screen and (min-width:$md) {
          max-height: 225px;
      }

        input {
            padding: 10px;
            width: calc(100% - 20px);
            margin: 0 auto;
            font-size: 24px;

            @media screen and (min-width: $md) {
                padding: 0 10px;
                font-size: 16px;
            }
        }

        li {
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid #f5f5f5;
            font-size: 24px;
            cursor:pointer;

            @media screen and (min-width: $md) {
                margin-bottom: 15px;
                padding-bottom: 10px;
                font-size: 16px;
            }

            span {
                margin-right: 10px;
                vertical-align: middle;
                margin-top: -5px;
            }
        }
    }
  }


}

//Iphone selector fix

#iphone-selector{
  @media only screen and (min-width: $sm){
      display: none;
  }
}