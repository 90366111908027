.m-panel {
  &-container {
    @extend .l-container;
    margin-bottom: 5px;

    &.inner-warning,
    &.warning-user {
      position: relative;
      .warning-box {
        background-color: #ffff9c;
        padding: 10px;
        font-size: 13px;
        .text-warning {
          span {
            font-style: italic;
          }
        }
      }
    }
  }

  &-text-image {
    font-size: 14px;
    line-height: 18px;
    margin: 0 0 15px;
  }

  &--centered {
    text-align: center;
  }

  /* type: hero */
  &-hero {
    padding: 20px 0;
    @media only screen and (min-width: $sm) {
      padding: 40px 0;
    }

    &-header {
      margin-bottom: 40px;
      text-align: center;

      @media (max-width: 767px) {
        margin-bottom: 0;
      }
    }

    &-body {
      margin-bottom: 40px;

      @media (max-width: 767px) {
        margin-bottom: 0;
      }
    }

    &-footer {
      text-align: center;
    }

    &--bg {
      background: $m-panel-hero-bg-color;
    }

    &--dark {
      background: $m-panel-hero-bg-dark-color;
    }

    &--grey {
      background: $m-panel-hero-bg-grey-color;
    }

    &--light {
      background: $m-panel-hero-bg-light-color;
    }

    &--lighter {
      background: $m-panel-hero-bg-lighter-color;
    }

    &-justText {
      text-align: center;
    }

    &-ribbon {
      padding: 20px 0;

      .m-panel-hero-header {
        margin-bottom: 20px;
      }

      .m-panel-hero-body {
        margin-bottom: 20px;
      }
    }
  }

  &-highlight {
    background-color: $color-wt;
    p {
      font-size: 16px;
    }
    &-block {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 15px;
      @media screen and (min-width: $lg) {
        flex-direction: row;
        justify-content: space-between;
      }
    }

    &-block-reverse {
      flex-direction: row;
      @media screen and (min-width: $lg) {
        flex-direction: row-reverse;
      }
    }
  }

  &-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  /* type: medium */
  &-medium {
    // padding: 15px 40px;
    padding: 0 30px;
    &-header {
      margin-bottom: 30px;
    }

    &-body {
      margin-bottom: 30px;
    }

    &--light {
      background: $color-wt;
    }

    &--darker {
      background: $color-gr-lighter;
    }

    @media only screen and (max-width: $sm) {
      padding: 0px 10px;
    }
  }

  /* type: regular */
  &-regular {
    &-header {
      margin-bottom: 30px;
      display: flex;
      align-items: center;

      &-icon {
        float: left;
        width: 60px;
        padding: 5px 10px 0 0;
      }

      @at-root .renewals .m-panel-regular-header-home {
        display: flex;
        justify-content: center;
      }
    }

    &-body:not(.d-layout) {
      margin-bottom: 30px;
    }

    &--light {
      background: $color-wt;
    }

    &--darker {
      background: $color-gr-lighter;
    }
  }

  /* type: small */
  &-small {
    padding: 20px 0;
    /*     @media only screen and (max-width: $sm) {
      padding: 10px 0;
    } */

    &-header {
      text-align: center;
    }

    &-footer {
      text-align: center;
      color: $color-gr-dark;
      font-size: 14px;
    }

    &--bg {
      background: $color-pr;
    }

    &--dark {
      background: $color-pr-dark;
    }

    &--light {
      background: $color-pr-light;
    }
  }

  &-box {
    margin-top: 10px;
    margin-bottom: 40px;
  }

  /* type: fields */
  &-fields {
    &-header {
      margin: 15px 0 3px;
      width: 100%;
    }

    &--bg {
      background: $color-pr;
    }

    &--dark {
      background: $color-pr-dark;
    }

    &--light {
      background: $color-pr-light;
    }
  }

  &-info {
    border: 1px solid $color-pr-lighter;
    margin: 5px 0 20px;
    width: 100%;
    text-align: center;
    padding-left: 0;
    padding-right: 0;

    &--lighter {
      border: 1px solid $color-gr-lighter;
      margin: 0;
    }

    &-light {
      background: $color-pr-light;
    }

    &-header {
      text-align: center;
      background-color: $color-pr-lighter;
      padding: 10px 5px;
      color: $color-pr;
    }

    &-header {
      text-align: center;
      background-color: $color-pr-lighter;
      padding: 10px 5px;
      color: $color-pr;

      &--lighter {
        background-color: $color-gr-lighter;
      }
    }

    &-body {
      padding: 20px;
      text-align: center;
    }

    &-link {
      display: block;
      font-size: 18px;
      padding: 5px 0 10px;
      text-decoration: unset;
    }

    .m-check-container {
      margin-top: 15px;
    }
  }

  &-instructions {
    margin: 0 0 10px 0;
    padding: 0 10%;
    @media only screen and (max-width: $md) {
      padding: 0;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      text-align: left;
      padding: 0px 15px 0 15px;
      color: $color-pr;
      position: relative;
      z-index: 20;

      span {
        font-size: 80px;
        position: relative;
        top: 13px;
        margin-right: 5px;
      }
    }

    &-body {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      min-height: 230px;
      position: relative;
      top: -25px;
      z-index: 10;
      padding: 30px 30px 0 30px;
      text-align: left;
      background-color: $color-wt;
    }
  }

  /* type: full size */
  &-full {
    display: flex;
    width: 100vw;
    position: fixed;
    height: 100vh;
    top: 0;
    justify-content: center;
    align-items: center;

    &--lighter {
      background: $color-gr-lighter;
    }

    &--translucent {
      background: $color-gr-translucent;
    }

    &--icon {
      width: 25%;
      margin: 0 0 5% 0;
      max-width: 200px;
    }
    @media only screen and (min-width: $sm) {
      &--icon-small {
        width: 15%;
        margin: 0 0 0 0;
      }
    }

    &--overflow {
      overflow: auto;
    }
  }

  &-quote {
    padding-left: 23px;
    margin-top: 15px;
    font-style: italic;
  }

  &-list {
    margin: 0 0 30px;

    li {
      list-style: disc;
      list-style-position: inside;
      line-height: 21px;
      list-style: none;

      &:not(:last-child) {
        margin: 0 0 15px;
      }
    }
  }

  &-image-center {
    display: flex;
    align-items: center;
    justify-content: center;
    &--column {
      @extend .m-panel-image-center;
      flex-direction: column;
    }
  }

  &-review {
    background: rgb(255, 255, 255);

    @media screen and (min-width: $md) {
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) 60%,
        rgba(230, 240, 245, 0.3) 60%
      );
    }

    padding: 10px;
  }

  &-tyc {
    padding: 2rem 1rem;
    margin-top: 2.5rem;
    border-top: 1px dotted #d4d4d4;
  }

  &-content {
    ol {
      list-style: decimal;
      padding-left: 15px;
      line-height: 1.3;
      font-size: 14px;
      li {
        margin-bottom: 8px;
      }
    }
    ul {
      list-style: circle;
      padding-left: 15px;
      line-height: 1.3;
      font-size: 14px;
      li {
        margin-bottom: 8px;
      }
    }

    .m-modal-body .m-table-terms {
      font-size: 15px;
    }

    table {
      border-collapse: collapse;

      @media screen and (min-width: $md) {
        margin-top: 1rem;
        margin-bottom: 2rem;

        thead tr {
          position: relative;
          visibility: visible;
        }

        th {
          background-color: $color-gr-light;
        }

        td,
        th {
          border: 1px solid #999;
          padding: 0.5rem;
        }
      }

      @media screen and (max-width: $md) {
        display: block;

        thead,
        tbody,
        th,
        td,
        tr {
          display: block;
        }

        td,
        th {
          text-align: left;
        }

        thead tr {
          position: absolute;
          visibility: hidden;
        }

        tr {
          margin-bottom: 25px;
        }

        td {
          border: none;
          position: relative;
          padding-left: 50%;
          margin-bottom: 3px;
        }

        td:nth-of-type(odd) {
          background: $color-gr-light;
        }
        td:nth-of-type(even) {
          background: $color-gr-lighter;
        }

        td:before {
          position: absolute;
          font-weight: 700;
          font-size: 12px;
          top: 3px;
          /* height: 100%; */
          left: 15px;
          /* width: 90%;  */
          padding-right: 10px;
          /* white-space: nowrap; */
        }

        td:before {
          content: attr(data-title) " ";
        }
      }
    }
  }
}

.l-payment-pop {
  .m-panel-info-body {
    text-align: left;
  }
}

#app-step-personal {
  @media only screen and (max-width: $sm) {
    padding-top: 5px;
  }
}

/*** COVID SITUATION */
.tramites-visa-americana {
  .m {
    &-panel {
      &-hero {
        @media only screen and (max-width: $md) {
          margin-top: 35px;
        }
      }
      &-small {
        &--bg {
          background-color: $color-pr;
        }
      }
    }
  }
}

.bb3006-vcontrol .m-panel-hero-header {
  margin-bottom: 40px;
}
