.e-icon {
  font-size: inherit;
  padding: 5px;
  fill: currentColor;
  vertical-align: middle;
  line-height: unset;
  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    &--top {
      @extend .e-icon__wrapper;
      flex-direction: column;
    }
    &--bottom {
      @extend .e-icon__wrapper;
      flex-direction: column-reverse;
    }
    &--right {
      @extend .e-icon__wrapper;
      flex-direction: row-reverse;
      justify-content: flex-end;
    }
    &--left {
      @extend .e-icon__wrapper;
      flex-direction: row;
      justify-content: flex-start;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      margin: 0;
    }
  }
  // if forget fill parameter on twig it shows that
  &__bug {
    margin: 5px 10px 5px 0;
    width: 18px;
    min-width: 18px;
    height: 18px;
    background: red;
    background-size: cover;
    background-repeat: no-repeat;
  }
  // Icon sizes
  $eIconSizes: small, medium, large;
  @mixin eIconPixels($eIconSize) {
    @if $eIconSize == small {
      font-size: inherit;
    } @else if($eIconSize == medium) {
      font-size: 24px;
    } @else if($eIconSize == large) {
      font-size: 32px;
    }
  }
  @each $eIconSize in $eIconSizes {
    &__#{$eIconSize} {
      @include eIconPixels($eIconSize);
    }
  }
  // icon color
  // &__pr {
  //   color: getColor("pr", "600");
  // }
  // &__sc {
  //   color: getColor("sc", "600");
  // }
  // &__nt {
  //   color: getColor("nt", "0");
  // }
  &__green::before {
    color: #37B152;
  }
  &__red::before {
    color: #DD2E44;
  }
  &__text {
    margin: 10px 0 0 30px;
    display: block;
  }
}

.m {
  &-table {
    &-regular {
      &-icon {
        .e-icon {
          &.i-close-circle, &.i-check-circle {
            font-size: 20px;
          }
        }
      }
    }
  }
}
