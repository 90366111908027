// JS required

//Style Tab Panel
.m-tab-panel{
  .tablist {
    padding-bottom: 1.5rem;

    @media screen and (min-width:$lg) {
      padding-bottom: 0;
      border-right: 1px solid $color-gr-light;
      padding-right: 0;
    }

    .e-button {
      display: block;
      padding: 20px 15px;
      color: $color-gr;
      text-decoration: none;
      font-size: 18px;

      &.active {
        background: $color-pr-title;
        color: $color-wt;
      }

    }

    &.tablist-ul {
      display: none;
    }

    @media screen and (min-width:$lg) {
      &.tablist-select {
        display: none;
      }
      &.tablist-ul {
        display:block;
      }
    }

  }


  .tab-content {
    
    @media screen and (min-width:$lg) {
      padding: 0 40px;
    }
    
    .tab-pane {
      display: none;

      &.active {
        display: block;
      }
    }

    iframe {
      width: 100%;
      margin-top:1rem;
      margin-bottom:1rem;
    }
  }
}
