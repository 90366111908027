.m-steps {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    max-width: $lg;
    @media only screen and (max-width: $sm) {
      display: none;
    }

    /*orientation: vertical sm only*/
    &-v-sm {
      @media only screen and (max-width: $sm) {
        flex-direction: column;
      }
    }

    &-step {
      max-width: 33%;
      text-align: center;
      padding: 0 7px;
      @media only screen and (max-width: $sm) {
        align-self: center;
        margin-bottom: 30px;
      }
    }

    &-stepBg {
      width: 215px;
      text-align: center;
      padding: 0 7px;
      position: relative;
      @media only screen and (max-width: $sm) {
        align-self: center;
        margin-bottom: 55px;
      }
  
      &:last-child {
        @media only screen and (max-width: $sm) {
          margin-bottom: 0;
        }
      }
    }

  }