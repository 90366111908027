// Icon + Decos
.e-deco-{
 &squere{
   border: 1px solid $color-wt;
   font-weight: 400;
   display: inline;
   padding: 1px 9px;
   margin: 6px 12px 5px 5px;
   color: $color-pr-light;
   font-size: 34px;
 }
}