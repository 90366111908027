
    // =============================================================================
    // String Replace
    // =============================================================================
    @function str-replace($string, $search, $replace: "") {
        $index: str-index($string, $search);
        @if $index {
            @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
        }
        @return $string;
    }
    // =============================================================================
    // Font Face
    // =============================================================================
    @mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg, $display: swap) {
        $src: null;
        $extmods: ( eot: "?", svg: "#" + str-replace($name, " ", "_") );
        $formats: ( otf: "opentype", ttf: "truetype" );
        @each $ext in $exts {
            $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
            $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
            $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
        }
        @font-face {
            font-family: quote($name);
            font-style: $style;
            font-weight: $weight;
            src:
            local(quote($name)),
            $src;
            font-display: $display;
        }
    }

@if variable-exists(font_merriweather) {

    $merriweather-url: "../../fonts/merriweather/";
    @include font-face("Merriweather", #{$merriweather-url + Merriweather-Regular}, $weight: 400,  $style: normal, $exts: ttf);

} 

@if variable-exists(font_work) {

    $workSans-url: "../../fonts/work_sans/";
    @include font-face("Work Sans", #{$workSans-url + WorkSans-Regular}, $weight: 400,  $style: normal, $exts: ttf);

} 

@if variable-exists(font_roboto) {
    //Roboto
    $roboto-url: "../../fonts/roboto/";
    
    @include font-face("Roboto", '#{$roboto-url + Roboto-Bold}', $weight: 700,  $style: normal, $exts: ttf);
    @include font-face("Roboto", '#{$roboto-url + Roboto-Regular}', $weight: 400,  $style: normal, $exts: ttf);
    @include font-face("Roboto", '#{$roboto-url + Roboto-RegularItalic}', $weight: 400,  $style: italic, $exts: ttf);

    //Roboto Slab
    $robotoSlab-url: "../../fonts/roboto_slab/";
    @include font-face("Roboto Slab", #{$robotoSlab-url + RobotoSlab-Bold}, $weight: 700,  $style: normal, $exts: ttf);
    @include font-face("Roboto Slab", #{$robotoSlab-url + RobotoSlab-Medium}, $weight: 500,  $style: normal, $exts: ttf);
    @include font-face("Roboto Slab", #{$robotoSlab-url + RobotoSlab-Light}, $weight: 300,  $style: normal, $exts: ttf);
}

@if variable-exists(font_montserrat) {
    //Roboto
    $montserrat-url: "../../fonts/montserrat/";
    @include font-face("Montserrat", #{$montserrat-url + Montserrat-Bold}, $weight: 700,  $style: normal, $exts: ttf);
    @include font-face("Montserrat", #{$montserrat-url + Montserrat-Regular}, $weight: 400,  $style: normal, $exts: ttf);
    @include font-face("Montserrat", #{$montserrat-url + Montserrat-Italic}, $weight: 400,  $style: italic, $exts: ttf);
}
