.m-table {
  &-regular {
    margin: auto;
    //max-width: 733px;
    width: 100%;
    @media only screen and (max-width: $xxs) {
      font-size: 14px !important;
      margin-left: -10px;
      width: 310px;
    }
    @extend .e-p-regular;
    th {
      padding: 10px;
      vertical-align: bottom;
    }
    td {
      padding: 10px 20px 10px 20px;
      @media only screen and (max-width: $xxs) {
        max-width: 90px;
        width: 100%;
      }
    }
    tbody tr:nth-of-type(odd) {
      background: $m-table-tr-odd-color;
    }
    tbody tr:nth-of-type(even) {
      background: $m-table-tr-even-color;
    }
    &-icon {
      text-align: center;
    }
  }
  &-img-s25 img {
    width: 25px;
  }

  &-terms thead,
  &-terms tbody {
    @media screen and (max-width: $md) {
      display: revert !important;
    }
  }

  &-terms tr {
    @media screen and (max-width: $md) {
      display: flex !important;
      margin-bottom: 0px !important;
    }
  }

  &-terms thead {
    & tr {
      @media screen and (max-width: $md) {
        position: initial !important;
        padding-bottom: 6px !important;
        visibility: initial !important;
      }
    }

    & td {
      @media screen and (max-width: $md) {
        width: 100% !important;
        word-wrap: break-word;
        word-break: break-word;
      }
    }
  }

  &-terms td,
  &-terms th {
    @media screen and (max-width: $md) {
      word-wrap: break-word;
      word-break: break-word;
      width: 100% !important;
      padding-left: 5px !important;
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
  }
}
