.e-link{
  &-disabled{
    color: currentColor;
    display: inline-block;  /* For IE11/ MS Edge bug */
    pointer-events: none;
    text-decoration: none;
    &-container{
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}