.m-footer {
  &-prefooter {
    background: $color-pr-dark;
    padding-bottom: 20px;
    p {
      color: white;
      font-size: 14px;
      text-align: center;
    }
    .e-p-svg {
      z-index: 1;
      filter: invert(1);
    }
    &-container {
      @extend .l-container;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 15px 0px;
      @media only screen and (max-width: $sm) {
        padding: 15px;
      }
      /* &:first-child{
        border-bottom: 1px solid #fff;
      } */
      &-bar {
        /* border-bottom: 1px solid white; */
        margin-bottom: 5px;
      }
    }
    &-secondary {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-logo {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      img {
        max-width: 150px;
        max-height: 50px;
        @media screen and (min-width: $md) {
          max-width: 225px;
          max-height: 80px;
        }
      }
    }
    &-linksContainer {
      display: flex;
      @media only screen and (max-width: $sm) {
        flex-direction: column;
      }
      a {
        padding: 8px;
        color: $color-wt;
        display: block;
        font-size: 14px;
        text-decoration: none;
        font-family: $font-sc;
        &:hover {
          color: $color-pr-light;
        }
      }
    }
    .e-card__container {
      margin-top: 0;
    }
    .m-canada-badge {
      display: flex;
      margin: 0 1rem;
      flex-direction: row;

      img {
        max-width: 160px;
        align-self: center;
        margin: 0px 15px 5px -5px;

        @media (min-width: $md) {
          max-width: inherit;
          min-width: 160px;
          align-self: inherit;
          margin: 0;
          margin-right: 15px;
        }
      }

      p,
      a {
        text-align: left;
        font-size: 11px;
        line-height: 1.2;
        margin-bottom: 5px;
        text-decoration: none;
        color: white;
      }
    }
  }
  &-main {
    &-container {
      @extend .l-container;
      padding: 20px 0;
      @media only screen and (max-width: $sm) {
        padding: 20px 15px;
      }
    }
  }

  &--autorizacion {
    padding-top: 15px !important;
    .m-footer-prefooter-container {
      padding: 0;
      border-bottom: 1px solid white;
    }
  }
}

.svg {
  &-footer {
    &-dims {
      margin-bottom: -6px;
      height: 19px;
      &--white {
        filter: invert(1);
      }
    }
    &-margin {
      margin-left: -15px;
      margin-right: -15px;
    }
  }
}
