.e-text, .e-textarea, .e-select {
  &.parsley-success {
    color: #468847;
    background-color: #DFF0D8;
    border: 1px solid #D6E9C6;
  }
}

.e-text, .e-textarea, .e-select {
  &.parsley-error {
    color: $color-tr-darker;
    background-color: $color-tr-lighter;
    border: 1px solid $color-tr-darker;
  }
}

.parsley-errors-list {
  padding: 0 10px;
  list-style-type: none;
  font-size: 12px;
  font-weight: 100;
  line-height: 1rem;
  opacity: 0;
  margin-top: 3px;
  color: $color-wt;
  background-color: $color-tr-dark;
  border: 1px solid $color-tr-dark;
  transition: all .3s ease-in;
  -o-transition: all .3s ease-in;
  -moz-transition: all .3s ease-in;
  -webkit-transition: all .3s ease-in;
  li {
    padding: 10px 0;

  }
}

.parsley-errors-list.filled {
  opacity: 1;
}