.e-d-layout ~ .m-panel-footer {
  padding: 10px 50px;
  @media only screen and (max-width: $xs) {
    padding: 0px;
    text-align: center;
  }
}

.e-buttons {
  &-regular {
    border-left: 8px solid $e-button-accent-color;
    overflow: hidden;
    box-shadow: inset 0 0 0 0 $color-sc-dark, 0 2px 4px 0 $color-shadow-main;
    padding: 23px 30px 23px 40px;
    position: relative;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    display: inline-block;
    background: $e-button-color;
    color: $e-button-text-color;
    /*font-size: 18px;*/
    /*New authorization buttons need smaller fonts*/
    font-size: 14px;
    letter-spacing: -0.5px;
    font-weight: 800;
    -webkit-transition: all ease 0.8s;
    -moz-transition: all ease 0.8s;
    transition: all ease 0.8s;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    white-space: nowrap;

    &::after {
      content: "";
      position: absolute;
      border-top: 33px solid transparent;
      border-bottom: 33px solid transparent;
      border-left: 7px solid $e-button-accent-color;
      top: 0;
      left: 0;
    }

    @media only screen and (max-width: $sm) {
      font-size: 15px;
    }

    &:hover {
      text-decoration: none;
      color: $e-button-text-hover-color;
      @media only screen and (min-width: $lg) {
        box-shadow: inset 460px 0 0 0 $e-button-accent-color, 0 2px 4px 0 $color-shadow-main;
      }
    }

    &--select {
      @extend .e-buttons-regular;
      margin-top: 15px;
      border-right: none;
      border-top: none;
      border-bottom: none;
      width: 100%;
    }
  }

  &-small {
    padding: 15px 20px 15px 40px;
    border: 0px;
    border-left: 4px solid $color-sc-dark;
    position: relative;
    text-transform: uppercase;
    text-decoration: none;
    display: inline-block;
    box-shadow: 0 2px 4px 0 $color-shadow-main;
    background: $e-button-color-small;
    color: $e-button-text-color;
    font-size: 14px;
    font-weight: 800;
    margin-bottom: 5px;
    cursor: pointer;
    @media only screen and (max-width: $sm) {
      width: 100%;
      border-left: 0;
      border-bottom: 4px solid $color-sc-dark;
      padding: 15px 20px;
    }

    &:hover {
      text-decoration: none;
    }

    &:before {
      content: "";
      background: url(#{$img-url-common}/icons/arrow-right.svg) no-repeat;
      width: 24px;
      left: 21px;
      height: 18px;
      position: absolute;
      @media only screen and (max-width: $sm) {
        display: none;

      }
    }

    &--clear {
      background-color: $color-gr-light;
      border: 0px;
      padding: 15px 20px 15px 20px;
      text-transform: uppercase;
      text-decoration: none;
      color: $color-wt;
      font-size: 14px;
      font-weight: 800;
      margin-bottom: 5px;
      @media only screen and (max-width: $sm) {
        width: 100%;
      }

      &:before {
        background: none;
      }
    }
    &#next-welcome {
      text-align: center;
    }
  }

  &-secure {
    padding: 15px 20px 15px 40px;
    border: 0px;
    position: relative;
    text-transform: uppercase;
    text-decoration: none;
    display: inline-block;
    background-color: $e-button-secure-color;
    color: $color-wt;
    font-size: 14px;
    font-weight: 800;
    margin-bottom: 5px;
    cursor: pointer;
    background-image: url(#{$img-url-common}/payment/secure-icon.png);
    background-repeat: no-repeat;
    background-position-y: center;
    background-size: 45px;
    @media only screen and (max-width: $sm) {
      width: 100%;
      border-left: 0;
      padding: 15px 20px;
    }

    &:hover {
      text-decoration: none;
    }

    &--clear {
      background-color: $color-gr-light;
      border: 0px;
      padding: 15px 20px 15px 20px;
      text-transform: uppercase;
      text-decoration: none;
      color: $color-wt;
      font-size: 14px;
      font-weight: 800;
      margin-bottom: 5px;
      @media only screen and (max-width: $sm) {
        width: 100%;
      }

      &:before {
        background: none;
      }
    }
  }

  &-mini {
    background-color: $color-sc;
    border: 0px;
    margin-top: 2px;
    padding: 7px;
    text-transform: uppercase;
    text-decoration: none;
    color: $e-button-text-color;
    font-size: 14px;
    font-weight: 800;
    min-width: 70px;
    line-height: 20px;
  }

  &-invalid {
    filter: saturate(0);
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      background: gray;
    }
  }

  &-icon {
    position: relative;

    &:before {
      content: "";
      position: absolute;
    }

    &--phone {
      &:before {
        background: url(#{$img-url-common}/icons/phone.svg);
        height: 15px;
        width: 15px;
        left: -20px;
        top: 2px;
      }
    }
  }

  &-disabled {
    background-color: $color-gr-light;
    border: 0;
  }

  &-nosizer {
    border-left: 4px solid $m-nav-button-accent-color;
    overflow: hidden;
    box-shadow: inset 0 0 0 0 $m-nav-button-accent-color,;
    padding: 23px 30px 23px 40px;
    position: relative;
    text-decoration: none;
    display: inline-block;
    background: $m-nav-button-color;
    color: $e-button-text-color;
    -webkit-transition: all ease 0.8s;
    -moz-transition: all ease 0.8s;
    transition: all ease 0.8s;
    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      border-top: 22px solid transparent;
      border-bottom: 22px solid transparent;
      border-left: 5px solid $color-sc-dark;
      top: 0;
      left: 0;
    }

    &:hover {
      text-decoration: none;
      color: $e-button-text-hover-color;
      @media only screen and (min-width: $sm) {
        box-shadow: inset 160px 0 0 0 $color-sc-dark;
      }
    }

    @media only screen and (max-width: $sm) {
      text-align: center;
      margin: 0 auto;
    }
  }

  &-spacemenu {
    margin-left: auto;

    @media only screen and (min-width: $sm) {
      margin-left: 50px;
    }
  }

  &-white {
    @extend .e-buttons-small;
    background:#fff;
    padding: 5px 20px 5px 20px;
    font-size: 15px;
    border-left: none;
    border-bottom: none;
    min-height: 36px;
    border-radius: 4px;
    font-weight: 400;
    text-align: center;
    color:$color-gr;
    box-shadow: 0 4px 5px 0 rgba($color-gr,.3);
    text-transform: inherit;
    letter-spacing: 0.025rem;
    display: flex;
    justify-content: center;
    align-items: center; 
    transition:0.2s linear box-shadow;
    @media (min-width: 767px) {
      padding: 11px 10px 11px 10px;
    }

    &:before {
      display: none;
    }

    &:hover {
      box-shadow: 0 5px 10px 2px rgba($color-gr,0.24);
    }

    &:focus {
      box-shadow: 0 1px 6px 1px rgba($color-gr,0.24);
    }
  }
  &-emergency{
    padding: 8px 15px;
    width: 150px;
    text-align: center;
    @media only screen and (min-width: $md) {
      width: 175px!important;
      padding: 15px 5px!important;
    }
  }
  &-visaregular{
    @media only screen and (min-width: $md) {
      width: 115px!important;
      padding: 15px 5px!important;
    }    @media only screen and (min-width: $md) {
      width: 115px!important;
      padding: 15px 5px!important;
    }
    &--nav{
      background-color: #4faedb;
      box-shadow: inset 0 0 0 0 #109adb;
      border-left: 4px solid #109adb;
      &:after{
        content: "";
        position: absolute;
        border-top: 22px solid transparent;
        border-bottom: 22px solid transparent;
        border-left: 5px solid #109adb;
        top: 0;
        left: 0;
      }
      &:hover{
        box-shadow: inset 160px 0 0 0 #109adb!important;
      }
    }
  }

  &-ctasingle {
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;

    a {
      max-width: 300px;
    }
  }

  &-currency{
    padding: 7px 25px 7px 25px;
    border-radius: 18px;
    border: none;
    background-color: white;
    box-shadow: 0px 2px 10px 0px #D6D8E1;
    cursor: pointer;

    &--selected{
      background-color:#87b7dc;
      transition: ease-in 0.1s;
    }
  }
  
}

.citas-emergencia .btn-renew.e-buttons-small {
/*   .e-buttons-small { */
    padding: 20px 35px 20px 35px!important;
    box-shadow: none!important;
    border-bottom: none!important;
    border-left: 4px solid #109adb!important;
    font-size: 16px!important;
    &:before{
      content: ""!important;
      background:none!important;
    }
    @media only screen and (max-width: 767px){
      width: auto!important;
    }
  }
/*   } */

.l-center--column,
.l-left--column, {
    .e-buttons-regular {
        
        width: 100%;
        margin-bottom: 10px;

        @media only screen and (min-width: $lg) {
            width: 230px;
            margin-bottom: 25px;
        }

    }
}