.e-label {
  font-size: 14px;
  color: $form-label-color;
  margin-right: 5px;
  display: inline;
  line-height: 22px;
  font-family: $font-pr;
  padding-bottom: 5px;
  @media (max-width: $xxs) {
    font-size: 13px;
  }
  .required {
    color: $color-tr;
  }
  &--xsmall{
    font-size: 11px;
    line-height: 16px;
  }
  &.required-icon {
    &:after {
      content: "*";
      color:$color-tr;
    }
  }
  &-review {
    @extend .e-label;
    margin-right: 0;
    text-align: center;
    display:block;

    @media screen and (min-width: $md) {
      padding-right: 20px;
      text-align: right;
    }

  }

  &[for="accept-terms"] {
    display: none;
  }
}

.e-no-label {

    @media (min-width: $md) {
      margin-top: 25px;
    }

    &-sibling {
      white-space: nowrap;
    }

}

.e-radio-container label {
  padding: 2px;
  line-height: 1.5;
}

.radio-delivery .e-radio-container label {
  display: block;
  margin-bottom: 15px;
}