.js-language-switcher-mobile {
  display: none;
  @media (max-width: $lg) {
    display: flex;
    position: absolute;
    right: 390px;
    top: 22px;
  }
  @media (max-width: $sm) {
    display: flex;
    position: relative;
    right: inherit;
    top: inherit;
  }
  .current-language--container {
    &:after {
      background-image: url(#{$img-url-common}/icons/down-blue.svg);
      margin-left: 5px;
    }
    &--item {
      color: #00518e;
      text-decoration: none;
    }
  }
}

.js-language-switcher {
  display: flex;
  position: relative;
  @media (max-width: $lg) {
    display: none;
  }
}

.current-language--container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 35px;
  margin: auto;
}

.language-switcher {
  margin-left: auto;
  z-index: 100;
  .flag {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    &--en {
      background: url("../../img/common/languages/flag-en.svg") no-repeat center;
    }
    &--es {
      background: url("../../img/common/languages/flag-es.svg") no-repeat center;
    }
    &--pt {
      background: url("../../img/common/languages/flag-pt.svg") no-repeat center;
    }
    &.active {
      border: 1px solid black;
      border-radius: 50%;
    }
  }
}

.current-language--container {
  &:after {
    content: "";
    color: white;
    font-size: 20px;
    background: url(#{$img-url-common}/icons/down.svg);
    background-repeat: no-repeat;
    width: 15px;
    height: 15px;
    background-size: contain;
    background-position: center;
    transition: 0.25s ease all;
  }
}

.active {
  .current-language--container:after {
    content: "";
    color: white;
    font-size: 20px;
    font-weight: 700;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
}

.active .other-languages {
  top: calc(100% + 5px);
}

.js-language-switcher-mobile.active .other-languages {
  top: calc(100% + 5px);
}

.other-languages {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  top: 0;
  right: 0;
  background: #e1eaf4;
  min-width: 220px;
  box-shadow: 0 2px 13.8px 0 rgba(0, 0, 0, 0.15);
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  border-left: 2px solid #0a2f4f;
  transition: 0.4s;
}

.js-language-switcher-mobile .other-languages {
  top: 0;
  left: inherit;
  right: -67px;
  min-width: 220px;
  transition: 0.4s;
}

.active .other-languages {
  visibility: visible;
  opacity: 1;
  z-index: 10;
}

.other-languages--label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 600;
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 14px;

  .close {
    content: "";
    display: flex;
    margin-left: auto;
    width: 20px;
    height: 20px;
    background: url("../../img/common/icons/close.svg") no-repeat center;
    cursor: pointer;
  }
}

.other-languages--list {
  padding: 0;
  margin: 0;
  width: 100%;
}

.other-languages--list--item {
  padding: 0 20px;
  a {
    color: rgba(0, 0, 0, 0.8) !important;
  }
  &:last-child {
    margin-bottom: 20px;
  }
  &:hover {
    background-color: #323232;
    a {
      color: white !important;
    }
  }
}

.current-language--container--item {
  padding-left: 0px;
}

.m-nav-submenu-inline {
  display: none;
  .m-two-callToAction {
    // min-width: 280px;
  }
  .m-two-callToAction .l-half {
    padding: 0 7px;
  }
}

.other-languages--list--item a.other-languages--list--item--link {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.07px;
  text-align: left;
  text-decoration: none;
  font-size: 14px;
  padding: 15px 10px;
  width: 100%;
  &.active {
    font-weight: bold;
  }
}

.other-languages--list--item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
