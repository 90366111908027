.e-card {
  height: 22px;
  width: 35px;
  background-size: 35px;
  background-repeat: no-repeat;
  margin-right: 2px;
  display: inline-block;
  &-large {
    height: 54px;
    width: 54px;
    background-position: center;
    background-size: 53px;
    background-repeat: no-repeat;
    margin-right: 2px;
    display: inline-block;
    @media (max-width: $xs) {
      width: 45px;
      background-size: 45px;
    }
  }
  &__container {
    margin-bottom: 0;
    padding-bottom: 15px;
    margin-top: 10px;
    &--right {
      text-align: right;
      @media (max-width: $sm) {
        text-align: center;
      }
    }
    @media (max-width: 767px) {
      width: 100%;
      margin-bottom: 0;
      border-bottom: 1px solid #d0d3dd;
    }
  }
  &--paypal-white {
    background-size: 65px !important;
    height: 55px !important;
    width: 65px !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-image: url(#{$img-url-common}/payment/paypal_white.png) !important;
  }
  &--amex {
    background-image: url(#{$img-url-common}/payment/amex.svg);
  }
  // &--diners {
  //   background-image: url(#{$img-url-common}/payment/diners.svg);
  // }
  &--discover {
    background-image: url(#{$img-url-common}/payment/discover.svg);
  }
  &--jcb {
    background-image: url(#{$img-url-common}/payment/jcb.svg);
  }
  &--maestro {
    background-image: url(#{$img-url-common}/payment/maestro.svg);
  }
  &--mastercard {
    background-image: url(#{$img-url-common}/payment/mastercard.svg);
  }
  &--visa {
    background-image: url(#{$img-url-common}/payment/visa.svg);
  }
  &--visa-mono {
    background-image: url(#{$img-url-common}/payment/visa-mono.svg);
  }
  &--v-mono {
    background-image: url(#{$img-url-common}/payment/v-mono.svg);
  }
  &--secure {
    background-image: url(#{$img-url-common}/icons/sello_secure.png);
  }
  &--success {
    background-image: url(#{$img-url-common}/icons/sello_success.png);
  }
  &--methods--footer {
    text-align: center;
    padding-top: 30px;
  }
}

.js-accordion-toggle .e-card-large.e-card--paypal {
  background-image: url(#{$img-url-common}/payment/paypal.png);
  background-size: 75px !important;
  width: 75px !important;
}
