$font_roboto: true;
$font_roboto: true;

$upload-file-text: "Subir Archivo";

$img-url-common: "../../img/common";
$img-url: "../../img/themes/amber/style-scheme-1";

// Font Family
$font-pr:  	    'Roboto', sans-serif;
$font-sc:  		'Roboto Slab', serif;

// Primary Colours
$color-pr: #00518E;
$color-pr-light: lighten($color-pr, 65%);
// TODO: calculate color values with percentages of first variable
$color-pr-lighter: #87B7DC;
$color-pr-dark: #023B66;
$color-pr-darker: #083559;
$color-pr-title: #0098EC;

// Secondary Colours
$color-sc: #3bc256; //Call to action
$color-sc-dark: #31a848; //Call to action
$color-sc-darker: #216A2C; //Call to action

// Tertiary Colours
$color-tr: #FF0000; //Alert
$color-tr-light: #f5c6cb;
$color-tr-lighter: #f8d7da;
$color-tr-dark: #DC3545;
$color-tr-darker: #B94A48;

// Values and Greyscale
$color-bk: #494949;
$color-bk100: #000000;
$color-wt: #fff;
$color-gr: #666A6E;
$color-gr-light: #C1C7D0;
$color-gr-lighter: #F4F5F7;
$color-gr-lightest: #f7f7f7;
$color-bg-jumbotron: #F0F5FF;
$color-gr-dark: #A5ADBB;
$color-gr-translucent: rgba(244, 245, 247, 0.9);

// Shadows
$color-shadow-main: rgba(0,0,0,0.5);
$color-shadow-ligther: rgba(0,0,0,0.18);
$color-shadow-ligthest: rgba(0,0,0,0.05);

$form-label-color: #727272;


// Grid

$lg: 1200px;
$md: 991px;
$sm: 767px;
$xs: 575px;
$xxs: 350px;

$m-nav-color: $color-wt;
$m-nav-submenu-color: $color-pr-darker;
$m-nav-submenu-container-color: $color-pr-dark;
$m-nav-submenu-a-color: $color-wt;
$color-titles: $color-pr;
$e-title-medium-color: $color-pr;
$e-p-regular-dark-color: $color-pr;

$e-title-hero-color:$color-pr;
$e-title-subhero-color:$color-pr;

//Buttons
$e-button-secure-color:$color-sc;
$m-nav-button-color: $color-sc;
$m-nav-button-accent-color: $color-sc-dark;
$e-button-color: $color-sc;
$e-button-color-small: $color-sc;
$e-button-accent-color: $color-sc-dark;
$color-button-social-media: $color-sc-dark;

$e-button-text-color: $color-wt;
$e-button-text-hover-color: $color-wt;

//Panel
$m-panel-hero-bg-color: $color-pr;
$m-panel-hero-bg-dark-color: $color-pr-dark;
$m-panel-hero-bg-grey-color: $color-gr-light;
$m-panel-hero-bg-light-color: $color-pr-light;
$m-panel-hero-bg-lighter-color: $color-gr-lighter;

//Table

$m-table-tr-odd-color: $color-pr-light;
$m-table-tr-even-color: $color-wt;
