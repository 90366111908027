.e-icon {
  &-phone {
    background-image: url(#{$img-url-common}/icons/phone.svg);
    height: 22px;
    width: 22px;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.5rem;
    float: left;
  }

  &-location {
    background-image: url(#{$img-url-common}/icons/location.svg);
    height: 26px;
    width: 26px;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.5rem;
    float: left;
  }

  &-flag {
    width: 20px;
    height: 15px;
    margin-right: 0.5rem;
    /* background-size: contain; */
    background-repeat: no-repeat;
    @extend .e-icon-flag;
  }
}

.e-icons {
  &-md {
    width: 100%;
    max-width: 150px;
    margin-left: auto;
    margin-right: auto;
  }
}
