// COOKIES TEST VWO

.m-cookies {
  padding: 15px 15px 5px;
  line-height: 28px;
  &-container {
    display: none;
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100%;
    text-align: center;
    min-height: 40px;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    z-index: 99999;
  }
  @media (max-width: $sm ) {     
      position: static;
      padding: 10px 7px 5px;
  }
  p {
    line-height: 28px;
    display: inline-block;
    @media (max-width: $sm ) {  
      font-size: 12px;
      line-height: 25px;
    }
  }
  a {
    color: $color-wt;
  }
}

/* nota: aplicar a mobile only!*/
.m-wrapper {
  height: 100vh;
  &-body {
    height: 85vh;
    overflow: scroll;
  }
  &-footer{
    height: 15vh;
    position: fixed;
    bottom: 0;
  }
}
/*end nota*/
