body {
  font-family: $font-pr;
}

p {
  font-size: 14px;
  line-height: 18px;
  margin: 0 0 15px;
}

h1,
h2 {
  font-size: 22px;
  line-height: 35px;
  color: $color-titles;
  margin: 0;
  @media only screen and (max-width: $sm) {
    font-size: 18px;
  }
}

i {
  font-style: italic;
}

.show {
  display: block !important;
}

.hide {
  display: none !important;
}

.hide-sm {
  @media only screen and (max-width: $sm) {
    display: none !important;
  }
}

.d-none-testab {
  display: none !important;
}

#error .m-content--options p {
  @media only screen and (max-width: $xxs) {
    text-align: center;
  }
}
