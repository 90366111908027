article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none !important;
}

figure {
  margin: 1em 40px;
}

hr {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

a.close {
  color: $color-gr;
}

.modal-open {
  overflow: hidden;
}

.modal {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}

.modal.fade .modal-dialog {
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  -o-transform: translate(0, -25%);
  transform: translate(0, -25%);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  &.centered {
    transform: translateY(25%);
    -o-transform: translateY(25%);
    -webkit-transform: translateY(25%);
    -ms-transform: translateY(25%);
  }
}

.modal.show .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}

.modal-content {
  position: relative;
  background-color: #fff;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
  filter: alpha(opacity=0);
}

.modal-backdrop.show {
  opacity: 0.5;
  filter: alpha(opacity=50);
}

.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
}

.modal-header .close {
  margin-top: -2px;
}

.modal-title {
  margin: 0 auto;
  line-height: 1.42857143;
}

.modal-body {
  position: relative;
  padding: 15px;
}

.modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}

.modal-footer .btn + .btn {
  margin-left: 5px;
  margin-bottom: 0;
}

.modal-footer .btn-group .btn + .btn {
  margin-left: -1px;
}

.modal-footer .btn-block + .btn-block {
  margin-left: 0;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }
  .modal-content {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
  .modal-sm {
    width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    width: 900px;
  }
}

.clearfix:before,
.clearfix:after,
.modal-header:before,
.modal-header:after,
.modal-footer:before,
.modal-footer:after {
  content: " ";
  display: table;
}

.clearfix:after,
.modal-header:after,
.modal-footer:after {
  clear: both;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.show {
  display: block !important;
}

.invisible {
  visibility: hidden;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.hidden {
  display: none !important;
}

.affix {
  position: fixed;
}

.m-modal {
  @extend .modal;

  &-content {
    @extend .modal-content;
    padding: 5%;
    overflow-x: hidden;
  }
  &-body {
    div {
      font-size: 14px;
      line-height: 18px;
      margin: 0 0 15px;
    }
  }
  &-footer {
    @extend .modal-header;
    border: 0px;
    text-align: center;
  }

  .m-modal-header {
    @extend .modal-header;
    border: 0;

    .close {
      max-height: 85vh;
    }

    &-header {
      @extend .modal-header;
      border: 0;
    }

    &-body {
      @extend .modal-body;
      border: 0px;
      overflow: scroll;
      max-height: 57vh;
      margin-top: 40px;
    }

    &-footer {
      @extend .modal-header;
      border: 0px;
      text-align: center;
    }

    &-close {
      position: absolute;
      right: 25px;
      top: 25px;
      height: 23px;
      width: 23px;
      border: 1px solid $color-pr-dark;
      font-weight: bold;
      color: $color-pr-lighter;
      background-color: $color-pr-dark;
      line-height: 1.4;
      z-index: 9999;

      &:hover {
        color: $color-pr-dark;
        background-color: $color-pr-lighter;
      }
    }
  }

  &.background-opacity {
    background: rgba(0, 0, 0, 0.5);
  }

  &-countryselect {
    &--list {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      margin: 0 auto;
      width: 100%;
    }
  }
}

#countrySelect {
  .modal-title {
    color: $color-pr-title;
    font-size: 26px;

    @media (min-width: $sm) {
      font-size: 32px;
    }

    &#countrySelectTitle {
      img {
        max-height: 2rem;
        margin-left: -2px;
        margin-right: -2px;
        @media screen and (min-width: $md) {
          max-height: none;
        }
      }
    }
  }
  .modal-dialog {
    margin: 0;
    @media (min-width: $sm) {
      width: $sm;
      margin: 30px auto;
    }
    @media (min-width: $lg) {
      width: 1000px;
    }
  }
  .m-txt-modal-subtitle {
    color: $color-pr-title;
  }
  .modal-content-countries {
    position: relative;
    background-color: #fff;
    border: 1px solid #999;
    border: 1px solid rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    outline: 0;
    height: 100%;
    min-height: 100vh;
    @media (min-width: 768px) {
      border-radius: 6px;
      height: auto;
      min-height: 200px;
    }

    .row:not(:last-child) {
      margin-bottom: 10px;
    }

    /*     .button {
      width: calc(50% - 2rem);
      margin: 0.5rem 1rem;
    } */

    .modal-header {
      border-bottom: 0;
      padding: 15px;
      @media (min-width: 767px) {
        padding: 40px;
      }
      a {
        text-decoration: none;

        &.close {
          font-size: 32px;
        }
      }
    }
    .modal-body {
      padding: 0 30px 15px;
      @media (min-width: 767px) {
        padding: 0 80px 60px;
      }
    }

    .modal-title {
      font-family: "Roboto Serif", serif;
    }
  }
}

// Modal terms
#js-modal-terms,
#js-modal-privacy {
  .m-modal-content {
    text-align: right;
    .m-modal-close {
      background: transparent;
      outline: none;
      border: 0;
      font-size: 35px;
      margin-bottom: -10px;
    }
  }
  .m-modal-body {
    max-height: 75vh;
    overflow-x: hidden;
    text-align: left;
    span {
      display: inline-block;
    }
  }
  .e-p-svg {
    z-index: 1;
  }
}

//Reminder

#popup-reminder {
  @media (max-width: 425px) {
    padding-right: 0px !important;
  }
  .modal-dialog {
    @media (min-width: 768px) {
      width: 760px;
      margin: 220px auto;
    }
  }
  .modal-content-reminder {
    border-radius: 4px;
    height: auto;
    min-height: 200px;
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    outline: 0;
    padding-bottom: 15px;
    padding-top: 15px;
  }
  .modal-header {
    border-bottom: none;
    h3 {
      font-size: 26px;
      color: #1978bf;
      padding: 10px;
      margin: 0;
      border-radius: 4px 4px 0 0;
      text-align: center;
      @media (max-width: 425px) {
        text-align: left;
        font-size: 24px;
        padding-bottom: 10px;
      }
    }
    p {
      color: #344a5f;
      font-size: 17px;
      @media (max-width: 425px) {
        font-size: 16px;
        margin-top: 10px;
      }
    }
  }
  .m-modal-body {
    button {
      border-left: none;
      border-bottom: none;
      border-top: none;
      border-right: none;
      overflow: hidden;
      padding: 15px 25px 15px 25px;
      position: relative;
      text-decoration: none;
      display: inline-block;
      background: $e-button-color;
      color: #fff;
      font-size: 18px;
      font-weight: 800;
      text-transform: inherit !important;
      -webkit-transition: all ease 0.8s;
      -moz-transition: all ease 0.8s;
      transition: all ease 0.8s;
      cursor: pointer;
    }
    button#button-reminder-form {
      margin-top: 15px !important;
      margin-bottom: 15px !important;
    }
  }
  .m-modal-footer {
    @media (max-width: 425px) {
      padding-top: 10px;
    }
    ul {
      padding-right: 30px;
      padding-left: 140px;
      @media (max-width: 660px) {
        padding-left: 30px;
        padding-right: 10px;
        li {
          &:before {
            left: 1rem !important;
          }
        }
      }
      @media (max-width: 425px) {
        padding-right: 10px;
        padding-left: 30px;
        li {
          &:before {
            background-size: 15px;
            margin-top: 3px;
            left: 1rem !important;
            width: 20px;
          }
        }
      }
      li {
        &:before {
          content: "";
          position: absolute;
          width: 23px;
          height: 17px;
          background: url(#{$img-url-common}/icons/check-green.svg);
          background-repeat: no-repeat;
          left: 7.5rem;
          top: auto;
        }
        p {
          font-size: 17px;
        }
      }
    }
    h5 {
      color: #1978bf;
      font-size: 17px;
      font-weight: bold;
      padding-bottom: 15px;
      padding-top: 15px;
      padding-left: 90px;
      @media (max-width: 660px) {
        padding-left: 25px;
      }
      @media (max-width: 425px) {
        padding-left: 10px;
        padding-bottom: 0px;
      }
    }
    i {
      color: #37b152;
    }
  }

  .close {
    background: none;
    border: none;
    line-height: 0px;
    font-size: 24px;
    float: right;
    cursor: pointer;
    margin-top: -8px;
  }
  a {
    text-decoration: none;
  }
}

/* DISCOUNT MODAL */

#popup-discount {
  @media (max-width: 425px) {
    padding-right: 0px !important;
  }
  .modal-dialog {
    @media (min-width: 768px) {
      width: 500px;
      margin: 220px auto;
    }
  }
  .modal-content-discount {
    border-radius: 4px;
    height: auto;
    min-height: 200px;
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    outline: 0;
    padding: 20px;
  }
  .modal-header {
    border-bottom: none;
    h3 {
      font-size: 26px;
      color: #1978bf;
      padding: 10px;
      margin: 0;
      border-radius: 4px 4px 0 0;
      text-align: center;
      @media (max-width: 425px) {
        text-align: left;
        font-size: 24px;
        padding-bottom: 10px;
      }
    }
    p {
      color: #344a5f;
      font-size: 17px;
      @media (max-width: 425px) {
        font-size: 16px;
        margin-top: 10px;
      }
    }
  }
  .m-modal-body {
    padding: 0 15px;
    p {
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 15px;
      padding-right: 20px;
      padding-left: 20px;
    }
  }
  .m-modal-footer {
    padding-bottom: 30px;
    @media (max-width: 425px) {
      padding-top: 10px;
    }
    ul {
      padding-right: 30px;
      padding-left: 140px;
      @media (max-width: 660px) {
        padding-left: 30px;
        padding-right: 10px;
        li {
          &:before {
            left: 1rem !important;
          }
        }
      }
      @media (max-width: 425px) {
        padding-right: 10px;
        padding-left: 30px;
        li {
          &:before {
            background-size: 15px;
            margin-top: 3px;
            left: 1rem !important;
            width: 20px;
          }
        }
      }
      li {
        &:before {
          content: "";
          position: absolute;
          width: 23px;
          height: 17px;
          background: url(#{$img-url-common}/icons/check-green.svg);
          background-repeat: no-repeat;
          left: 7.5rem;
          top: auto;
        }
        p {
          font-size: 17px;
        }
      }
    }
    h5 {
      color: #1978bf;
      font-size: 17px;
      font-weight: bold;
      padding-bottom: 15px;
      padding-top: 15px;
      padding-left: 90px;
      @media (max-width: 660px) {
        padding-left: 25px;
      }
      @media (max-width: 425px) {
        padding-left: 10px;
        padding-bottom: 0px;
      }
    }
    i {
      color: #37b152;
    }
  }

  .close {
    background: none;
    border: none;
    line-height: 0px;
    float: right;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 12px;
    font-size: 32px;
    font-weight: 400;
  }
  a {
    text-decoration: none;
  }
}
