$stylescheme: "style-scheme-1";
@import "variables-style-1";
@import "../../../themes/amber/assets/scss/new_theme_pages";

.#{$stylescheme} {
  @media (max-width: 574px) and (max-width: 767px) {
    &.ds-160
      .m-panel-hero:not(.m-panel-hero--light)
      #payment_div
      .l-row
      .l-half:first-child,
    &.ds-160
      .m-panel-hero:not(.m-panel-hero--light)
      .l-container
      .l-row
      .l-half:first-child,
    &.ds-160
      .m-panel-hero:not(.m-panel-hero--light)
      .l-payment-pop
      .l-main
      #payment_div
      .l-row
      .l-half:first-child,
    &.ds-160
      .m-panel-hero:not(.m-panel-hero--light)
      .m-footer-main-container
      .l-row
      .l-half:first-child,
    &.ds-160
      .m-panel-hero:not(.m-panel-hero--light)
      .m-footer-prefooter-container
      .l-row
      .l-half:first-child,
    &.ds-160
      .m-panel-hero:not(.m-panel-hero--light)
      .m-jumbotron-home-container
      .l-row
      .l-half:first-child,
    &.ds-160
      .m-panel-hero:not(.m-panel-hero--light)
      .m-nav-menu-container
      .l-row
      .l-half:first-child,
    &.ds-160
      .m-panel-hero:not(.m-panel-hero--light)
      .m-nav-submenu-container
      .l-row
      .l-half:first-child,
    &.ds-160
      .m-panel-hero:not(.m-panel-hero--light)
      .m-nav2-menu-container
      .l-row
      .l-half:first-child,
    &.ds-160
      .m-panel-hero:not(.m-panel-hero--light)
      .m-nav2-submenu-container
      .l-row
      .l-half:first-child,
    &.ds-160
      .m-panel-hero:not(.m-panel-hero--light)
      .m-panel-container
      .l-row
      .l-half:first-child {
      background: $color-pr-light;
      padding-top: 25px;
      margin-top: -25px;
      margin-bottom: 25px;
    }
  }

  .js-spinner {
    z-index: 100;
  }
  @media only screen and (max-width: 990px) {
    &.citas-emergencia {
      .m-nav-submenu {
        .content-btn-nav {
          justify-content: center;
          display: flex;
          flex-wrap: wrap;
          padding-left: 15px;
          padding-right: 15px;
          a {
            flex: 1;
            &#button-navMenu-renewal {
              line-height: 1.8;
            }
          }
        }
      }
    }
  }
  &.contacto {
    .m-panel-hero--lighter {
      .e-p-clean {
        font-size: 15px;
      }
    }
  }
  &.homeBR-PT {
    .m-two-callToAction {
      flex: 1;

      a {
        width: 200px !important;
      }
      .content-btn-nav {
        padding: 5px 15px 1px 0px;
        display: flex;
        justify-content: flex-end;
      }
    }
    .m-jumbotron-home {
      margin-top: 0;
    }
  }

  &.gowithb1b2-com.home {
    @media only screen and (min-width: 767px) and (max-width: 1024px) {
      .m-steps {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        &-step {
          align-self: baseline;
          flex: 1;
        }
      }
    }
  }

  &.home {
    .m-panel-hero-body {
      .m-steps.m-steps-v-sm {
        .m-panel-regular-body {
          img {
            @media only screen and (min-width: 768px) and (max-width: 1024px) {
              max-width: 100px;
            }
          }
        }
      }
    }
  }

  // Test AB Landing Ecuador

  &.homeEC2 {
    .e-p-regular {
      font-family: "Roboto-regular", sans-serif;
    }
    .advice-in-jumbo {
      p {
        color: white;
      }
    }
    .jumbo-btn-content {
      @media only screen and (max-width: 767px) {
        text-align: center;
      }
    }
    .m {
      &-jumbotron {
        &-home {
          .m-two-callToAction {
            @media only screen and (min-width: 768px) and (max-width: 1023px) {
              text-align: center;
              .l-half {
                flex: 1;
              }
            }
          }
        }
      }
      &-panel {
        &-regular {
          &-header {
            flex-wrap: wrap;
            p {
              width: 100%;
            }
            @media only screen and (min-width: 768px) and (max-width: 1023px) {
              justify-content: center;
            }
          }
        }
        &-hero {
          padding-bottom: 0;
          &--light {
            background: #d3e6f84d;
            .l-container {
              .m-panel-regular-header {
                h1,
                h2 {
                  flex: 1;
                }
                .e-title-hero {
                  font-size: 92px;
                  @media only screen and (max-width: 767px) {
                    font-size: 75px;
                  }
                }
                .e-title-medium {
                  font-family: "Roboto Slab";
                  font-weight: 300;
                  font-size: 24px;
                }
              }
            }
          }
          &--companion {
            .e-title-hero {
              flex: 0.7 !important;
            }
            .l-row {
              @media only screen and (min-width: 768px) and (max-width: 1023px) {
                text-align: center;
                .m-two-callToAction .l-half {
                  flex: 1;
                }
              }
            }
          }
          &--companion,
          &--guia,
          &--entrevista {
            .l-half {
              align-self: center;
            }
          }
          &--guia {
            h1,
            h2 {
              flex: 1;
            }
            img {
              max-width: 60%;
              @media only screen and (min-width: 768px) and (max-width: 1023px) {
                max-width: 40%;
              }
            }
            .e-title-hero {
              font-size: 92px;
              @media only screen and (max-width: 767px) {
                font-size: 75px;
              }
            }
            .e-title-medium {
              font-family: "Roboto Slab";
              font-weight: 300;
              font-size: 24px;
              padding-bottom: 0;
              padding-left: 30px;
            }
          }
          &--full {
            padding-top: 50px;
            padding-bottom: 40px;
            .m-panel-regular-header {
              display: block;
              width: 100%;
            }
            .e-title-hero {
              margin-top: 25px;
              font-size: 56px !important;
              @media only screen and (max-width: 767px) {
                font-size: 40px !important;
              }
            }
            .e-title-subhero {
              @media only screen and (max-width: 767px) {
                padding: 5px;
              }
            }
            .e-p-regular {
              text-align: center;
              @media only screen and (max-width: 767px) {
                padding: 5px;
              }
            }
            .m-two-callToAction {
              width: 50%;
              text-align: center;
              @media only screen and (min-width: 768px) and (max-width: 1023px) {
                .l-half {
                  flex: 1;
                }
              }
            }
          }
          &--entrevista {
            padding-top: 0;
            .l-container {
              max-width: 100%;
            }
            .m-panel-regular-header {
              @media only screen and (max-width: 767px) {
                width: 100% !important;
                justify-content: center !important;
              }
              @media only screen and (min-width: 768px) and (max-width: 1023px) {
                text-align: center;
                width: 100% !important;
                justify-content: center;
                .e-title-subhero {
                  width: 100%;
                }
              }
            }
            .m-panel-regular-body {
              @media only screen and (max-width: 767px) {
                width: 100% !important;
                padding: 15px !important;
              }
              @media only screen and (min-width: 768px) and (max-width: 1023px) {
                width: 100% !important;
              }
            }
            .l-half {
              margin: 0;
              padding: 0;
              text-align: left;
              @media only screen and (min-width: 768px) and (max-width: 1023px) {
                text-align: center;
              }
              img {
                margin: 0;
                padding: 0;
                width: 85%;
              }
              .m-panel-regular-header,
              .m-panel-regular-body {
                width: 60%;
                @media only screen and (max-width: 1024px) {
                  width: 70%;
                }
              }
              &.l-center.hidden-xs {
                @media only screen and (min-width: 768px) and (max-width: 1023px) {
                  text-align: center;
                }
              }
            }
            .e-title-hero {
              margin-top: 25px;
              font-size: 56px !important;
            }
            ul {
              p {
                padding-left: 42px;
                margin-top: 5px;
              }
            }
            li {
              &:before {
                content: url("#{$img-url}/icons/advisor/ec2/check-circle.png");
                position: relative;
                top: 15px;
                margin-right: 15px;
              }
            }
          }
          &--asesoramiento {
            .m-two-callToAction {
              margin-top: 50px;
              margin-bottom: 75px;
              @media only screen and (min-width: 768px) and (max-width: 1023px) {
                text-align: center;
                .l-half {
                  flex: 1;
                }
              }
            }
            .l-half {
              align-self: center;
              &--description {
                margin-top: 30px;
              }
            }

            .e-title-subhero {
              margin-right: 90px;
              line-height: 1.3;
              @media only screen and (max-width: 767px) {
                .e-title-subhero {
                  margin: 0;
                }
              }
            }
          }
          &--steps {
            padding-bottom: 50px;
            padding-top: 50px;
            background-color: #0e3559;
            .e-icons-nextBg:after {
              content: "";
              display: none;
            }
            .m-steps-stepBg:nth-child(-n + 2) {
              margin-right: 30px;
            }
          }
          &--benefits {
            padding-top: 50px;
            padding-bottom: 50px;
            .l-container {
              max-width: 960px;
            }
          }
        }
      }
    }
    .e {
      &-buttons {
        &-regular {
          border: none;
          box-shadow: none;
          transition: none;
          border-radius: 5px;
          padding: 18px 15px;
          text-transform: capitalize;
          font-size: 16px;
          font-weight: 500;
          max-width: 200px;
          &:after {
            display: none;
          }
        }
        &-nosizer {
          border: none;
          box-shadow: none;
          transition: none;
          border-radius: 5px;
          &:after {
            display: none;
          }
        }
      }
      &-icons-md {
        @media only screen and (min-width: 768px) and (max-width: 1023px) {
          max-width: 40%;
        }
        max-width: 85%;
      }
    }
  }

  .m-footer {
    &-prefooter {
      &-container {
        &-bar {
          border-bottom: 1px solid white;
        }
      }
    }
  }

  #error {
    background: #dbefff;
    margin: 0;
    padding: 75px 0px 70px 0px;
    .m-header {
      h1 {
        font-size: 32px;
        margin: 20px 0px 20px 0px;
        color: $color-pr;
      }
      h2 {
        font-size: 24px;
        color: $color-pr;
        line-height: 1.3;
      }
    }
    .m-content {
      margin: 30px 0px 40px 0px;
      p {
        color: $color-pr;
        font-size: 18px;
      }
      &--options {
        display: inline-block;
        text-align: left;
      }
      a {
        color: $color-sc;
      }
    }
    .m-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      margin: 65px 0px 20px 0px;
      div:first-child {
        margin-right: 50px;
        @media only screen and (max-width: 425px) {
          margin-right: 10px;
        }
      }
      a {
        background-color: $color-sc;
        text-decoration: none;
        color: white;
        padding: 15px 32px 15px 32px;
        border-radius: 5px;
        @media only screen and (max-width: 425px) {
          padding: 15px 15px;
        }
      }
    }
  }
}
