.m-twocoltext {
     flex-direction: row;

     &--align-left {
          flex-direction: row-reverse;
     }

     h2 {
          @extend .e-title-hero;
          margin-bottom: 30px;
     }

     p {
          @extend .e-p-regular;
          padding-bottom: 15px;
     }

     ul {
          @extend .e-list-hero;
          list-style: disc;
          padding-left: 15px;
     }

     & .e-ul-check {
          list-style: none;
     }

/*      li {
          @extend .e-list-hero-li;
     } */

     .m-calltoaction {
          padding-bottom: 15px;
     }
}