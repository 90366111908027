.e-tooltip {
  border-radius: 65px;
  padding: 10px 7px 8px 7px;
  font-size: 11px;
  color: $e-button-text-color;
  font-weight: bold;
  display: inline-block;
  line-height: 0;
  background-color: $color-pr;
  border-radius: 65px;
  cursor: pointer;
}

.e-tooltip-innerbox {
  background-color: transparentize($color-pr, 0.25);
  color: $e-button-text-color;
  position: absolute;
  top: -30px;
  padding: 12px;
  line-height: 12px;
  border-radius: 10px;
  margin-right: 15px;
}

.tooltipster-light {
  @extend .tooltipster-light;
  font-size: 11px;
}

.tooltip_templates { display: none; }

.tooltipster-sidetip{
  &.tooltipster-light {
    .tooltipster-box{
      background: #ffffff;
    }

    &.tooltipster-top {
      .tooltipster-arrow-background {
        border-top-color: #ffffff;
      }
    }
  }
}

.inner-warning {
  position: relative;
  
  .warning-box {
      background-color: #ffff9c;
      padding: 10px;
      font-size: 13px;
      
      .text-warning {
          span {
              font-style: italic;
          }
      }
  }
}

.form-select-wrapper {
  .warning-box {
      background-color: #ffff9c;
      padding: 10px;
      font-size: 13px;
  }
}  
