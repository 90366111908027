.e-list {
  &-hero {
    font-size: 18px;

    &.d-layout {
      font-size: 16px;
      padding: 20px 65px;
      color: $color-pr-dark;
      @media only screen and (max-width: $sm) {
        padding: 20px 30px;
      }

      li {
        margin-bottom: 0.5rem;
      }
    }

    @media only screen and (max-width: $sm) {
      margin-left: 25px;
    }

    li {
      margin-bottom: 8px;
      position: relative;
      line-height: 1.5;

      &:before {
        position: absolute;
        width: 20px;
        height: 17px;
        background-repeat: no-repeat;
        left: -2rem;
        top: 0;
      }
    }
  }

  &-regular {
    font-size: 14px;
    line-height: 17px;
    padding: 10px 20px 15px 20px;
    text-align: left;

    li {
      margin-bottom: 8px;
      position: relative;
      list-style-type: disc;
    }
  }
}
